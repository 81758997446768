<template>
  <svg
    class="smooth-corners"
    :width="width"
    :height="height"
    xmlns="http://www.w3.org/2000/svg"
    :viewBox="`0 0 ${width} ${height}`"
    fill="currentColor"
  >
    <path :d="pathData" />
  </svg>
</template>

<script setup>
  import { computed, defineProps } from 'vue';
  import { getSvgPath } from 'figma-squircle';

  const props = defineProps({
    width: {
      type: [Number, String],
      default: 100,
    },
    height: {
      type: [Number, String],
      default: 100,
    },
    cornerRadius: {
      type: [Number, String],
      default: 10,
    },
  });

  const pathData = computed(() => {
    const width = Number(props.width);
    const height = Number(props.height);
    const cornerRadius = Number(props.cornerRadius);

    if (isNaN(width) || isNaN(height) || isNaN(cornerRadius)) {
      return '';
    }

    return getSvgPath({
      width,
      height,
      cornerRadius,
      cornerSmoothing: 1,
    });
  });
</script>
