<template>
  <div class="supplement-group">
    <h3 class="mb-m">
      {{ group.value }}
      <span v-if="group.required" class="required">({{ __t('app.required') }})</span>
    </h3>

    <div
      v-for="(item, j) in supplements"
      :key="`cat_${j}`"
      class="supplement-item space-between"
      :class="{'active' : false}"
    >
      <SupplementItem
        :delivery="delivery"
        :type="group.type.toString()"
        :item="item"
        :max="checkMax(group)"
        @update-supplement="val => updateSupplement(val)"
      />
    </div>

    <button
      v-if="props.group.supplements.filter(supp => Boolean(supp.visible) === true).length > 3"
      class="supplement-toggle-button"
      :class="{'more': showAll}"
      @click="toggleShowAll"
    >
      <Icon name="chevron" />
      {{ showAll ? __t('app.show.less') : __t('app.show.more') }}
    </button>
  </div>
</template>

<script setup>
  import {defineProps, defineEmits, ref, computed, onBeforeMount} from "vue";
  import SupplementItem from "@/modals/parts/SupplementItem.vue";
  import {__t} from "@/globals";
  import Icon from "@/ui/icon/Icon.vue";

  const props = defineProps({
    group: {
      type: Object,
      required: true
    },
    delivery: {
      type: Boolean,
      required: true
    }
  })

  const emit = defineEmits(['updateSupplement']);

  const showAll = ref(false);

  const toggleShowAll = () => {
    showAll.value = !showAll.value;
  }

  const supplements = computed(() => {
    return showAll.value
      ? props.group.supplements.filter(supp => Boolean(supp.visible) === true)
      : props.group.supplements.filter(supp => Boolean(supp.visible) === true).slice(0, 3)
  });

  const updateSupplement = (item) => {
    emit('updateSupplement', {group: props.group, item: item});
    checkMax(props.group);
  }

  const checkMax = (group) => {
    if (group.type === 'single') {
      return false;
    }

    if(group.max < 0) {
      return false;
    }

    console.log(group.supplements.filter(item => item.selected).length);

    return group.supplements.filter(item => item.selected).length >= group.max;
  }

  onBeforeMount(() => {
    console.log('SupplementGroup.vue mounted', props.group);
  });
</script>

<style lang="scss">
div.supplement-group {
  margin-bottom: var(--margin-xl);
  width: 100%;

  h3 {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 1;

    span.required {
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .supplement-item {
    margin-bottom: var(--margin-m);
    margin-right: var(--margin-s);
    cursor: pointer;
    height: 2rem;
    width: 100%;

    h4 {
      font-size: 1.2rem;
      display: flex;
      color: var(--r2e-secondary);
      align-items: center;

      span.price {
        font-size: 1rem;
        font-weight: 500;
        color: var(--r2e-secondary-200)
      }

      div.radio {
        color: var(--color-grey);
      }
    }
  }
}

[data-theme='dark'] {
  div.supplement-group {
    h3 {
      color: var(--r2e-white);
    }

    span.required {
      color: var(--r2e-secondary-200);
    }
  }

  div.supplement-item {
    h4 {
      color: var(--r2e-white);
    }
  }
}

.supplement-toggle-button {
  color: var(--r2e-primary);
  font-size: 1rem;
  font-weight: 600;
  background-color: transparent;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: var(--margin-xs);
    width: 0.8rem;
    height: 0.8rem;
    transform: rotate(-90deg);
    color: var(--r2e-primary);
  }

  &.more {
    svg {
      transform: rotate(90deg);
    }
  }
}
</style>
