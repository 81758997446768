import session from "@/modules/session";
import {validateAddressFields} from "@/modules/order/services/AddressService";
import {validateUserFields} from "@/modules/auth/services/UserService";
import {$openModal, $showToast, __t} from "@/globals";
import Order from "@/resources/Order";
import {apiUrl} from "@/configuration/api";
import axios from "axios";
import {valuta} from "@/modules/global/helpers/domHelper";
import OrderStatusEnum from "@/configuration/orderStatusEnum";
import {Encryption} from "@/modules/global/helpers/safe";
import PlatformService from "@/services/PlatformService.js";
import {Browser} from '@capacitor/browser';
import router from "@/router/index.js";


function toggleAsap(val) {
  session.commit('order/setArrivalTimePreference', val);
}

function setArrivalTime(date, time) {
  session.commit('order/setPreferredTimeOfArrival', date + ' ' + time);
}

function validateOrder() {
  let validation = true;
  let errorMessages = [];
  let minOrder = false;

  if (session.getters['cart/delivery']) {
    if (!validateAddressFields()) {
      validation = false;
      errorMessages.push(__t('error.validation.address_fields'))
    }
  }

  if (!validateUserFields()) {
    validation = false;
    errorMessages.push(__t('error.validation.user_fields'))
  }

  if (!validateEstablishment()) {
    validation = false;
    minOrder = true;
    errorMessages.push(
      __t('error.validation.min_order')
      + ' '
      + valuta(session.getters['cart/establishment'].min_order_amount)
    );
  }

  if (validation === false) {
    $openModal('notification', {messages: errorMessages, minOrder: minOrder})
  }

  return validation;
}

function validateEstablishment() {
  const delivery = session.getters['cart/delivery'];

  if (!delivery) {
    return true;
  }

  const subtotal = session.getters["cart/price_total"];
  const min_order = session.getters['cart/establishment'].min_order_amount;

  return subtotal >= min_order;
}

function resetCart() {

  session.commit('cart/reset');
  session.commit('order/reset');
}

async function submitOrder() {
  if (validateOrder()) {
    session.commit('setLoading', true);

    let order = createOrder();

    if (order.register) {
      session.commit('user/setRegistering', true);
      session.commit('user/setRegisteringEmail', order.user.email);
      session.commit('user/setRegisteringPassword', order.user.password);
    }

    if (process.env.ENCRYPT_PAYLOAD === "true") {
      order = {
        payload: encryptPayload(order),
      };
    }

    await axios.post(apiUrl('customer/order/create'), order).then(async ({data}) => {
      if (data.data) {
        resetCart();

        const orderObject = data.data;
        session.commit('order/setCurrentOrder', orderObject);

        if (orderObject.online_payment) {
          await goToPaymentURL(orderObject.callback_url, orderObject.nr);
        } else {
          await goToFollowOrder(orderObject.nr);
        }


      }
    }).catch((error) => {
      setTimeout(() => {
        if (error.response) {
          switch (error.response.data.key) {
            case 'email' :
              $showToast(__t('error.order.email'), 'error', 10000);
              break;
            case 'order-time' :
              $showToast(__t('error.order.order-time'), 'error', 10000);
              break;
            case 'closed' :
              $showToast(__t('error.order.closed'), 'error', 10000);
              break;
            case 'price' :
              $showToast(__t('error.order.price'), 'error', 10000);
              break;
            case 'order-lines' :
              $showToast(__t('error.order.order-lines'), 'error', 10000);
              break;
            case 'discount' :
              $showToast(__t('error.order.discount'), 'error', 10000);
              break;
            default :
              $showToast(__t('error.order.submit'), 'error', 10000);
          }
        } else {
          $showToast(__t('error.order.submit'), 'error', 10000);
        }

      }, 1000)

    }).finally(() => {
      session.commit('setLoading', false);
    });
  }
}

async function goToPaymentURL(molliePaymentURL, orderNr) {
  if (PlatformService.isNative()) {
    await Browser.open({url:molliePaymentURL}).then(() => {
      if(PlatformService.isAndroid()){
        router.push({name: 'order-follow-by-nr', params: {nr: orderNr}})
      }
    });
  } else {
    window.location = molliePaymentURL;
  }
}

async function goToFollowOrder(orderNr) {
  await router.push({name: 'order-follow-by-nr', params: {nr: orderNr}})
}

function encryptPayload(order) {
  const encryption = new Encryption();

  return encryption.encrypt(JSON.stringify(order), process.env.ENCRYPT_KEY);
}

function createOrder() {
  // init values
  let order = new Order();
  const status = OrderStatusEnum.QUEUING;
  const online_payment = session.getters['order/online_payment'];
  const delivery_mode = session.getters['cart/delivery'] ? 'delivery' : 'takeout';
  const comment = session.getters['order/comment'];

  const sub_total = session.getters['cart/price_sub_total'];
  const service_cost = session.getters['cart/price_service'];
  const fee = session.getters['cart/price_fee'];
  const total = session.getters['cart/price_total']
  const delivery_cost = session.getters["cart/price_delivery"];

  const discount_code = session.getters["cart/discount_code"]?.code;
  const discount_amount = session.getters["cart/discount_amount"];
  const discount_min_order_amount = session.getters["cart/discount_min_order_amount"];

  const arrival_timing_preference = session.getters["order/arrival_timing_preference"];
  const preferred_time_of_arrival = session.getters["order/preferred_time_of_arrival"];
  const estimated_time_of_arrival = session.getters["order/estimated_time_of_arrival"];

  const register = session.getters["user/register"];
  const user = session.getters["user/user"];
  const deliver_address = session.getters['getAddress'];
  const establishment_id = session.getters["cart/establishment"].id

  const order_lines = session.getters["cart/items"];
  const organization_id = session.getters["order/ready2help"] ? session.getters["order/ready2help"].id : null
  const app = process.env.API_KEY_ID;

  // attach values
  order.status = status;
  order.nr = null;
  order.comment = comment;

  order.online_payment = online_payment;
  order.delivery_mode = delivery_mode;

  order.sub_total = sub_total;
  order.fee = fee;
  order.delivery_cost = delivery_cost;
  order.service_cost = service_cost;
  order.total = total;
  order.discount_code = discount_code;
  order.discount_amount = discount_amount;
  order.discount_min_order_amount = discount_min_order_amount;

  order.arrival_timing_preference = arrival_timing_preference;
  order.preferred_time_of_arrival = preferred_time_of_arrival;
  order.estimated_time_of_arrival = estimated_time_of_arrival;

  order.establishment_id = establishment_id;
  order.deliver_address = deliver_address;
  order.order_lines = order_lines;

  order.user = user;
  order.register = register;

  order.organization_id = organization_id;
  order.app = app;

  setFollowOrderUrl(order);

  return order;
}

function setFollowOrderUrl(order) {
  if (PlatformService.isNative()) {
    order.follow_order_url = 'ready2eat://app.ready2eat.be/track-order';
    return;
  }

  const locale = localStorage.getItem('locale') ?? 'nl';
  const domain = window.location.origin;

  switch (locale) {
    case 'nl':
      order.follow_order_url = `${domain}/bestelling-volgen`;
      break;
    case 'en':
      order.follow_order_url = `${domain}/follow-order`
      break;
    case 'fr':
      order.follow_order_url = `${domain}/suivre-commande`
      break;
    default:
      order.follow_order_url = `${domain}/bestelling-volgen`;
  }

}


export {
  toggleAsap,
  setArrivalTime,
  submitOrder,
  validateOrder,
  resetCart
}
