import moment from "moment";

export function scrollToElement(el, height) {
  const content = document.getElementById('ionContent');

  const element = document.getElementById(el);
  const y = (element.getBoundingClientRect().top - (height ?? 100));

  getScrollPosition(content).then((windowY) => {
    this.$nextTick(() => {
      content.scrollToPoint(0, windowY + y, 500);
    })

  } );
}

export async function getScrollPosition(content) {
  return await content.getScrollElement().then((scrollElement) => {
    return scrollElement.scrollTop;
  });
}

export function maxLength(val, length) {
  return val.length <= length ? val : val.substring(0, length) + '...';
}

export function maxLengthByScreenWidth(val, length, setMAx) {
  if(setMAx){
    return val.length <= length ? val : val.substring(0, length) + '...';
  }

  return val;

}

export function checkEmail(email) {
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return pattern.test(email);
}

export function toFormattedDate(date)
{
  return moment(date).format('DD/MM/YYYY HH:mm');
}

export function valuta(value) {
  // Check if the value is a valid number
  if (typeof value !== 'number') {
    return '-,--';
  }

  const formattedValue = value.toFixed(2);

  const formattedString = formattedValue.replace('.', ',');

  const parts = formattedString.split(',');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  return `€${parts.join(',')}`;
}
