<template>
  <div class="article-skeleton modal-article">
    <div class="modal-header">
      <h2>
        <span class="skeleton-bg mb-s" style="height: 2.4rem; width: 12rem;" />
      </h2>
    </div>
    <div class="skeleton-bg" style="height: 1.4rem; width: 20rem;" />
    <div class="skeleton-supplement mt-xl">
      <div class="skeleton-bg mb-m" style="height: 2.2rem; width: 50%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 20%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 30%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 30%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 45%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 30%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 33%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 30%" />
    </div>
    <div class="skeleton-supplement mt-xl">
      <div class="skeleton-bg mb-m" style="height: 2.2rem; width: 50%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 20%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 30%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 30%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 45%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 30%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 33%" />
      <div class="skeleton-bg mt-s" style="height: 1.4rem; width: 30%" />
    </div>
  </div>
</template>

<script setup>

</script>

<style scoped>
.article-skeleton {
  padding: var(--padding-m);
  padding-top: var(--padding-l);

  .modal-header {
    position: relative;
    padding-top: var(--ion-safe-area-top);

    .exit-button {
      position: absolute;
      right: 1rem;
      top: 1rem;
      width: 1.8rem;
      height: 1.8rem;
    }

    h2 {
      margin-top: var(--margin-s);
      font-size: 1.6rem;
      font-weight: 700;
      color: var(--r2e-secondary);
      line-height: 1;
      display: flex;
      align-items: center;
    }
  }

  .skeleton-bg {
    background-color: var(--skeleton-bg);
  }

  .skeleton-supplement {
    margin-top: var(--margin-xl);
    .skeleton-bg {
      margin-bottom: var(--margin-m);
    }
  }
}
</style>
