<template>
  <div class="container" :class="{'native-container': native}">
    <slot />
  </div>
</template>

<script setup>
  import PlatformService from "@/services/PlatformService.js";

  const native = PlatformService.isNative();
</script>

<style lang="scss">
.container {
  max-width: 180rem;
  margin: auto;
  padding: 0 var(--padding-s);
  padding-bottom: calc(var(--padding-m) + var(--ion-safe-area-bottom));

  &.native-container {
    padding-bottom: var(--padding-m);
  }

  @media(min-width: 720px){
    padding: 0 var(--padding-m);
    padding-bottom: calc(var(--padding-m) + var(--ion-safe-area-bottom));
  }
}
</style>
