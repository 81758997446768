<template>
  <span
    v-if="allergies.length < 3 && allergies.length > 0"
    class="supplement-allergies"
    @click.stop.prevent
  >
    <template v-for="(allergy, j) in allergies" :key="`supplement-allergy_${j}`">
      <span class="allergy">
        <Icon :name="`allergy.${allergy.key}`" />
        <Tooltip>{{ allergy.value }}</Tooltip>
      </span>
    </template>
  </span>
  <span
    v-else-if="allergies.length >= 3 && allergies.length > 0"
    class="supplement-allergies"
    @click.stop.prevent
  >
    <span class="allergy">
      <Icon name="allergy.info" />
      <Tooltip style="max-width: 250px; height: auto">{{ getAllergiesString() }}</Tooltip>
    </span>
  </span>
</template>

<script setup>
  import {defineProps} from "vue";
  import {Icon} from "@/ui";
  import Tooltip from "@/modules/global/parts/Tooltip.vue";

  const props = defineProps({
    allergies: {
      type: Array,
      required: true
    }
  })

  const getAllergiesString = () => {
    return props.allergies.map(allergy => allergy.value).join(', ');
  }
</script>

<style lang="scss">
span.supplement-allergies {
  display: flex;
  margin: 0 0 0 var(--margin-s);

  span.allergy {
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: var(--margin-xs);
    position: relative;

    svg {
      width: 2rem;
      height: 2rem;
      color: var(--r2e-secondary-200);
    }

    &:hover {
      .tooltip {
        display: flex;
      }
    }
  }
}

[data-theme='dark']{
  span.supplement-allergies {
    span.allergy {
      svg {
        fill: var(--r2e-secondary-200);
      }
    }

  }
}
</style>
