<template>
  <app-modal-mini
    class="ion-modal-delivery"
    :button-disabled="arrival_timing_preference !== 'now' && disabled"
    @submitted="submit"
  >
    <template #header>
      <h3>
        <Icon name="clock" />
        {{ delivery ? __t('checkout.delivery-time.title') : __t('checkout.takeout-time.title') }}
      </h3>
    </template>

    <template #main>
      <div class="delivery-time-modal">
        <div class="toggle-group">
          <span :class="{active: asap}">{{ __t('checkout.delivery-time.asap') }}</span>
          <Toggle
            :active="asap"
            @click="togglePreference"
          />
        </div>

        <template v-if="!asap">
          <div class="mt-m or">
            {{ __t('app.or') }}
          </div>

          <div class="date-picker mt-m">
            <label>{{ __t('app.date') }}</label>
            <DatePicker
              class="mb-m"
              :value="inputs.date.value"
              :time="false"
              :configuration="configuration"
              @setter="val => inputs.date.value = val"
            />
          </div>

          <Time
            id="time"
            :value="inputs.time.value"
            :label="__t('app.time')"
            :placeholder="__t('app.time')"
            :error="inputs.time.error ? inputs.time.error_message : null"
            @set="val => inputs.time.value = val"
            @remove-error="inputs.time.error = false"
          />
        </template>

        <div v-if="!establishment.is_open" class="closed mt-s">
          (<template v-if="establishment.open_time.day === 'today'">
            {{ __t('app.establishment.today') }} {{ establishment.open_time.time }}
          </template>
          <template v-else-if="establishment.open_time.day === 'tomorrow'">
            {{ __t('app.establishment.tomorrow') }} {{ establishment.open_time.time }}
          </template>
          <template v-else>
            {{ __t('app.establishment.other_day') }} {{ establishment.open_time.day }} - {{ establishment.open_time.time }}
          </template>)
        </div>
      </div>
    </template>
  </app-modal-mini>
</template>

<script setup>
  import {ref, computed, onBeforeMount, defineProps, onMounted} from 'vue';
  import { Icon, Toggle, DatePicker, Time } from "@/ui";
  import AppModalMini from "@/modals/AppModalMini.vue";
  import {$showToast, __t} from "@/globals";
  import session from "@/modules/session";
  import { setArrivalTime, toggleAsap } from "@/modules/order/services/OrderService";
  import { getEstablishmentConfiguration } from "@/modules/establishment/services/EstablishmentService";
  import moment from "moment";
  import axios from "axios";
  import { apiUrl } from "@/configuration/api";
  import {modalController} from "@ionic/vue";

  defineProps({
    takAway: {type: Boolean, default: false}
  });

  const loading = ref(true);

  const asap = ref(true);

  const inputs = ref({
    date: {
      value: null,
    },
    time: {
      value: null,
      error: false,
      error_message: __t('error.input.time')
    }
  });

  const delivery = computed(() => session.getters["cart/delivery"]);
  const arrival_timing_preference = computed(() => session.getters['order/arrival_timing_preference']);
  const preferred_time_of_arrival = computed(() => session.getters['order/preferred_time_of_arrival']);
  const configuration = computed(() => getEstablishmentConfiguration());
  const establishment = computed(() => session.getters["cart/establishment"]);

  const disabled = computed(() => inputs.value.date.value === null || inputs.value.time.value === null);

  onBeforeMount(async () => {
    await fetchEstablishment();

    asap.value = (arrival_timing_preference.value === 'now');

    inputs.value.time.value = preferred_time_of_arrival.value
      ? moment(preferred_time_of_arrival.value, 'DD/MM/YYYY HH:mm').format('HH:mm')
      : configuration.value.startTime.hours + ":" + configuration.value.startTime.minutes;

    inputs.value.date.value = preferred_time_of_arrival.value
      ? moment(preferred_time_of_arrival.value, 'DD/MM/YYYY').toDate()
      : moment().toDate();

  });

  onMounted(async () => {
    setTimeout(async () => {
      await setBreakpoint();
    }, 500);

  });

  async function togglePreference() {
    asap.value = !asap.value;

    await setBreakpoint()
  }

  async function setBreakpoint() {
    const modal = await modalController.getTop();

    if (asap.value === true) {
      await modal.setCurrentBreakpoint(0.5);
    }else {
      await modal.setCurrentBreakpoint(1);
    }
  }

  async function fetchEstablishment() {
    const address = session.getters.getAddress;
    try {
      const { data } = await axios.get(apiUrl(`customer/establishment-by-id/${establishment.value.id}?latitude=${address.latitude}&longitude=${address.longitude}`));
      session.commit('cart/setEstablishment', data.data);
    } catch (error) {
      $showToast(__t('error.establishment.fetch'), 'error');
    } finally {
      loading.value = false;
    }
  }

  function submit() {
    if(asap.value){
      toggleAsap('now');
      setArrivalTime(moment(inputs.value.date.value).format('DD/MM/YYYY'), null);
      modalController.dismiss();
    }else {
      toggleAsap('other');

      if(inputs.value.date.value && inputs.value.time.value){
        setArrivalTime(moment(inputs.value.date.value).format('DD/MM/YYYY'), inputs.value.time.value);
        modalController.dismiss();
      }else {
        $showToast(__t('error.input.delivery-time'), 'error');
      }
    }
  }
</script>

<style lang="scss">
.ion-modal-delivery {
--height: 60rem;
}

.delivery-time-modal {
  .disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  .date-picker {
    label {
      font-size: 1.2rem;
      font-weight: 600;
      line-height: 2.6;
      color: var(--r2e-secondary);
      padding-bottom: 5px;
    }
  }

  .or {
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    color: var(--r2e-secondary-200);
  }

  .toggle-group {
    margin: var(--margin-l) 0;
    margin-bottom: var(--margin-m);
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    span {
      font-size: 1.2rem;
      font-weight: 600;
      margin-right: var(--margin-s);

      &.active {
        color: var(--r2e-primary);
      }
    }
  }

  .closed {
    font-size: 1rem;
    text-align: center;
    font-weight: 600;
    color: var(--r2e-secondary-200);
  }
}

</style>
