import axios from "axios";
import session from "@/modules/session";

export function apiUrl(url) {
  setAxiosCommonHeaders();
  return process.env.API_URL + '/v1/' + url;
}

export function setAxiosCommonHeaders(){
  axios.defaults.withCredentials = true;
  axios.defaults.headers.common['locale'] = localStorage.getItem('locale') ?? 'nl';
  axios.defaults.headers.common['Accept-Language'] = localStorage.getItem('locale') ?? 'nl';

  if(session.getters["user/token"]){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + session.getters["user/token"];
  }

}
