export default class Order {
  constructor() {
    this.status = null;
    this.nr = null;
    this.comment = null;

    this.online_payment = null;
    this.delivery_mode = null;

    this.sub_total = null;
    this.fee = null;
    this.delivery_cost = null;
    this.total = null;

    this.arrival_timing_preference = null;
    this.preferred_time_of_arrival = null;
    this.estimated_time_of_arrival = null;

    this.establishment_id = null;
    this.deliver_address = null;
    this.order_lines = null;

    this.user = null;

    this.app = null;
    this.organization_id = null;
  }
}
