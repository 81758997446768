<template>
  <div class="modal-footer">
    <div class="group">
      <div class="supplement-group mb-m">
        <div class="space-between align-items-center">
          <h3>{{ __t('app.quantity') }}</h3>
          <cart-item-counter
            id="article-counter"
            :value="cartItem.quantity"
            @add="emit('add-item')"
            @min="emit('min-item')"
          />
        </div>
      </div>

      <div class="button-group">
        <app-button id="article-add-button" icon="add" @click="emit('submit-article')">
          ({{ valuta(cartItem.price_total) }})
          {{ __t('app.add-to-order') }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import {defineProps, defineEmits} from 'vue';
  import {__t} from "@/globals.js";
  import {valuta} from "@/modules/global/helpers/domHelper.js";
  import Counter from "@/modules/global/Counter.vue";
  import {Button, Icon} from "@/ui/index.js";
  import AppButton from "@/ui/button/AppButton.vue";
  import CartItemCounter from "@/modules/cart/parts/CartItemCounter.vue";

  defineProps({
    cartItem: {type: Object, required: true},
  });

  const emit = defineEmits(['add-item', 'min-item', 'submit-article']);
</script>

<style lang="scss">
.modal-footer {
  padding: var(--padding-m);

  div.supplement-group {
    margin-bottom: var(--margin-l);
  }

  .button-group {
    display: flex;
    justify-content: center;
  }
}

[data-theme='dark']{
  .modal-footer {
    .button-group {
      .corner-mask {
        background-color: var(--r2e-secondary) !important;
      }
    }
  }
}
</style>
