<template>
  <Multiselect
    v-model="value"
    :options="options"
    :close-on-select="true"
    :searchable="false"
    :show-labels="false"
    :label="label"
    :allow-empty="false"
    :placeholder="placeholder"
    @update:model-value="updateSelected"
  />
</template>

<script>

  import Multiselect from "vue-multiselect";
  import "vue-multiselect/dist/vue-multiselect.css";
  import {__t} from "@/globals";

  export default {
    name: "R2eSelect",
    components: { Multiselect },
    props: {
      placeholder: { type: String, required: false, default: __t('app.select') },
      options: { type: Array, required: false, default: new Array(0) },
      selected: { type: String, required: false, default: null },
      label: { type: String, required: false, default: null },
      labelExtra: { type: String, required: false, default: null },
    },
    emits: ["setter", "removeError"],
    data() {
      return {
        value: null,
      };
    },
    beforeMount() {
      this.value = this.selected;
    },
    methods: {
      updateSelected() {
        this.$emit("setter", this.value);
        this.$emit("removeError");
      },
    },
  };
</script>

<style lang="scss">
.multiselect {
  * {
    font-family: 'Poppins', sans-serif !important;
  }

  &.multiselect--disabled {
    opacity: 0.4 !important;
  }

  .multiselect__tags {
    background-color: var(--r2e-secondary-100);
    border: 1px solid transparent;
    border-radius: 4px;
    height: 6.2rem;
    display: flex;
    align-items: center;
    padding-left: var(--margin-m);

    .multiselect__placeholder {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  .multiselect__single {
    background-color: transparent;
    color: var(--r2e-secondary);
    font-weight: 500;
    font-size: 1.6rem;
  }

  .multiselect__option {
    height: 6.2rem;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: var(--r2e-secondary)
  }

  .multiselect__option--highlight.multiselect__option {
    background-color: var(--r2e-primary);
    color: #fff;

    span {
      color: #fff;
    }
  }

  .multiselect__select {
    height: 6rem;
  }
}

[data-theme='dark'] {
  .multiselect {
    .multiselect__tags {
      background-color: var(--r2e-secondary-500);
    }

    .multiselect__single, .multiselect__option {
      color: var(--r2e-white);
    }

    .multiselect__option {
      background-color: var(--r2e-secondary-500);
      color: var(--r2e-white);

      span {
        color: var(--r2e-white);
      }
    }

    .multiselect__option--highlight.multiselect__option {
      background-color: var(--r2e-primary);
      color: var(--r2e-white);

      span {
        color: var(--r2e-white);
      }
    }
  }
}
</style>
