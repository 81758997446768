export default class User {

  id;
  first_name;
  last_name;
  email;
  phone;
  password;
  register;

  constructor() {
    this.id = null;
    this.first_name = null;
    this.last_name = null;
    this.email = null;
    this.phone = null;
    this.password = null;
    this.register = null;
  }
}
