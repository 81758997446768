<template>
  <span class="toggle-register">
    <template v-if="register">
      <b class="active">{{ __t('checkout.create-account') }}</b>
    </template>
    <template v-else>
      <b>{{ __t('checkout.create-account') }}</b>
    </template>

    <Toggle :active="register" @toggle="toggled" />
  </span>
</template>

<script setup>
  import {computed, defineEmits} from 'vue';
  import {Toggle} from "@/ui";
  import {__t} from "@/globals";
  import {toggleRegister} from "@/modules/auth/services/UserService";
  import session from "@/modules/session";

  const register = computed(() => session.getters['user/register']);

  const emit = defineEmits(['toggled']);

  const toggled = () => {
    toggleRegister();
    emit('toggled');
  };
</script>

<style lang="scss">
.toggle-register {
  display: flex;
  font-size: 1.2rem;
  align-items: center;

  b {
    margin-right: var(--margin-xs);
    font-weight: 600;

    &.active {
      color: var(--r2e-primary);
    }
  }
}
</style>
