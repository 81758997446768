<template>
  <div class="r2e-input" style="height: 6.1rem">
    <label v-if="label">{{ label }}</label>
    <div class="input-field search-field">
      <vue-google-autocomplete
        id="places"
        ref="places"
        v-model="location"
        :api-key="apiKey"
        types="address"
        country="be"
        class="google-search-location"
        :placeholder="placeholder ? placeholder : __t('app.banner.search.placeholder')"
        @placechanged="setAddressBySearchInput"
        @keypress.enter="enterClicked"
      />
      <button class="search" @click="emit('submit')">
        <Icon name="search" />
      </button>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, defineEmits, defineProps, watch } from 'vue';
  import { getAddressString, setAddress } from "@/modules/order/services/AddressService";
  import { __t } from "@/globals";
  import VueGoogleAutocomplete from "vue-google-autocomplete";
  import session from "@/modules/session";
  import Icon from "@/ui/icon/Icon.vue";

  const props = defineProps({
    placeholder: { type: String, required: false, default: null },
    autoSubmit: { type: Boolean, required: false, default: true },
    label: { type: String, required: false, default: null }
  });

  const emit = defineEmits(['canSearch', 'submit', 'setAddress']);

  const apiKey = process.env.GOOGLE_MAPS_WEB_API_KEY;
  const location = ref(session.getters['getAddress'] ? getAddressString(session.getters['getAddress']) : '');

  const places = ref(null);

  onMounted(() => {
    if (places.value) {
      places.value.$el.value = location.value;
    }
  });

  watch(location, (newLocation) => {
    if (places.value) {
      places.value.$el.value = newLocation;
    }
  });

  const setAddressBySearchInput = (addressData) => {
    if (props.autoSubmit) {
      setAddress(addressData);
    } else {
      emit('setAddress', addressData);
    }
    emit('canSearch');
    emit('submit');
  };

  const enterClicked = () => {
    emit('submit');
  };
</script>

<style lang="scss">
.search-field {
  height: 6.1rem;
  position: relative;

  button.search {
    background-color: transparent;
    position: absolute;
    top: calc(50% - 1rem);
    right: var(--margin-m);
    z-index: 2;

    i svg {
      width: 2rem;
      height: 2rem;
      color: var(--r2e-secondary-200);
    }
  }
}
</style>
