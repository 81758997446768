export default {
  namespaced: true,
  state: {
    updated: false,
    currentEstablishment: null,
  },
  mutations: {
    setUpdated(state) {
      state.updated = !state.updated;
    },
    setCurrentEstablishment(state, establishment) {
      state.currentEstablishment = establishment;
    },
  },
  getters: {
    updated : (state) => state.updated,
    currentEstablishment: (state) => state.currentEstablishment
  },
  actions: {
    setUpdated({commit}, value) {
      commit('setUpdated', value)
    },
    setCurrentEstablishment({commit}, establishment) {
      commit('setCurrentEstablishment', establishment)
    },
  },
};
