<template>
  <div class="checkbox">
    <template v-if="!checked">
      <svg
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="17"
          height="18"
          rx="3"
          stroke-width="2"
        />
      </svg>
    </template>
    <template v-else>
      <svg
        class="active"
        viewBox="0 0 19 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          width="19"
          height="20"
          rx="4"
        />
        <g clip-path="url(#clip0_0_1)">
          <path d="M15.1454 4.77734L8.01224 11.3949L4.99556 8.17555L3 10.0455L7.8736 15.2465L17 6.78764L15.1454 4.77734Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_0_1">
            <rect
              width="14"
              height="14"
              fill="white"
              transform="translate(3 3)"
            />
          </clipPath>
        </defs>
      </svg>
    </template>
  </div>
</template>

<script>
  export default {
    name: "R2eCheckbox",
    props: {
      checked: { type: Boolean, required: false, default: false }
    },
  }
</script>

<style lang="scss">

.checkbox {
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
  stroke: var(--r2e-secondary-200);

  svg {
    width: 2.4rem;
    height: 2.4rem;
    transition: var(--effect);

    &.active {
      stroke: transparent;
      fill: var(--r2e-primary);

      rect {
        fill: var(--r2e-primary);
      }
    }
  }
}
</style>
