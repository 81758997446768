import router from "@/router";

/**
 *
 * @param route
 */
function goTo(route){
  return router.push({name: route});
}

function canGoBack(){
  return router.options.history.state.back;
}

function goBack(){
  const currentRoute = router.currentRoute.value;

  if(currentRoute.name === "not-found"){
    return router.push({name : 'catalogue'})
  }

  return router.back();
}

export {
  goTo,
  canGoBack,
  goBack
}
