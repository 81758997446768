<template>
  <modal-mini :submit-txt="__t('checkout.modal-submit.submit')" @submitted="submit">
    <template #header>
      <h3>{{ __t('checkout.modal-submit.title') }}</h3>
    </template>

    <template #main>
      <div class="modal-submit">
        <template v-if="delivery">
          <template v-if="online_payment">
            <p>{{ __t('checkout.modal-submit.delivery.online-payment.body') }}</p>
          </template>
          <template v-else>
            <p>{{ __t('checkout.modal-submit.delivery.cash.body') }}</p>
          </template>
        </template>
        <template v-else>
          <template v-if="online_payment">
            <p>{{ __t('checkout.modal-submit.takeaway.online-payment.body') }}</p>
          </template>
          <template v-else>
            <p>{{ __t('checkout.modal-submit.takeaway.cash.body') }}</p>
          </template>
        </template>

        <p>{{ __t('checkout.modal-submit.confirm-text') }}</p>
      </div>
    </template>
  </modal-mini>
</template>

<script>
  import ModalMini from "@/modals/parts/ModalMini.vue";
  import {__t} from "@/globals";
  import session from "@/modules/session";

  export default {
    name: "ModalDeliveryTime",
    components: {ModalMini},
    data(){
      return {
        preferred_time_of_arrival : null,
        disabled: true,
        code: null
      }
    },
    computed: {
      delivery(){
        return session.getters['cart/delivery'];
      },
      online_payment(){
        return session.getters['order/online_payment'];
      }
    },
    methods: {
      __t,
      submit(){
        // todo: submit order
        //this.$emit('close');
      }
    },
  }
</script>

<style lang="scss">
.modal-submit {
  p {
    text-align: center;
    font-weight: 500;
    font-size: 1.2rem;
    margin: var(--margin-s) 0;
  }
}
</style>
