import { createRouter, createWebHistory } from '@ionic/vue-router';
import routes from "@/router/routes";
import session from "@/modules/session";
import { useFilter } from '@/configuration/filter';
import {LocaleService} from "@/configuration/localeService.js";
import PlatformService from "@/services/PlatformService.js";
import {Network} from "@capacitor/network";

const supportedLocales = ['nl', 'en', 'fr'];  // List of supported locales

// Function to generate localized routes based on the current locale
function generateLocalizedRoutes(locale) {
  return routes.map(route => {
    return {
      name: route.name,
      path: route[`path_${locale}`],
      component: route.component,
      middleware: route.middleware,
      redirect: route.redirect ? routes.find(r => r.name === route.redirect)[`path_${locale}`] : null
    };
  });
}

// Initial locale setting from localStorage or default
const initialLocale = localStorage.getItem('locale') ?? 'nl';

let routeList = PlatformService.isNative() ? routes : generateLocalizedRoutes(initialLocale);

const router = createRouter({
  history: createWebHistory('/'),
  routes: routeList
});

router.beforeEach(async (to, from, next) => {
  const newLocale = to.query.locale;
  const currentLocale = localStorage.getItem('locale') ?? 'nl';

  if (newLocale && supportedLocales.includes(newLocale) && newLocale !== currentLocale) {
    LocaleService.changeLocale(newLocale, to);
    setTimeout(() => {
      location.reload();
    }, 500);
  } else if (to.meta.middleware) {
    const middleware = to.meta.middleware;
    middleware(to, from, next, session);
    next();
  } else {
    if (to.path !== from.path) {
      const filter = useFilter();
      filter.resetFilter();
      next();
    }
  }
});

export default router;
