<template>
  <Datepicker
    v-model="val"
    :placeholder="null"
    :format="format"
    :locale="locale"
    :teleport-center="true"
    :enable-time-picker="time"
    :auto-apply="true"
    :min-date="configuration?.minDate"
    :max-date="configuration?.maxDate"
    :min-time="configuration?.minTime"
    :disabled-week-days="configuration?.disabledDates"
    :select-text="__t('app.select')"
    :cancel-text="__t('app.cancel')"
    @update:model-value="setter(val)"
  />
</template>

<script setup>
  import { ref, watch, onBeforeMount, defineProps, defineEmits } from 'vue';
  import Datepicker from '@vuepic/vue-datepicker';
  import { __t } from '@/globals';
  import moment from 'moment';

  const props = defineProps({
    value: { type: String, default: null },
    format: { type: String, default: 'dd/MM/yyyy' },
    time: { type: Boolean, default: false },
    configuration: { type: Object, default: null }
  });

  const emit = defineEmits(['setter']);

  const val = ref(props.value);

  watch(() => props.value, newValue => {
    val.value = newValue;
  });

  onBeforeMount(() => {
    if (props.configuration) {
      val.value = moment(val.value).set(props.configuration.startTime);
    }
  });

  const setter = (value) => {
    if (value) {
      val.value = value;
      emit('setter', value);
    }
  };
</script>

<style lang="scss">
@import "datepicker";

</style>
