<template>
  <ion-header />
  <ion-content class="ion-padding content-radius" :class="classes">
    <slot />
  </ion-content>
  <ion-footer style="box-shadow: none">
    <div class="app-version">
      {{ __t('app.version') + ' ' + version }}
    </div>
  </ion-footer>
</template>

<script setup>
  import {defineProps} from 'vue';
  import {IonHeader, IonContent, IonFooter} from "@ionic/vue";
  import {__t} from "../globals.js";

  defineProps({
    classes: {
      type: String,
      required: false,
      default: ''
    },
  });

  const version = import.meta.env.VITE_APP_VERSION;
</script>

<style lang="scss">
ion-modal {
  --border-radius: 0rem;

  @media(min-width: 768px){
    --border-radius: 1rem;
  }

  div.app-version {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: lowercase;
    padding: var(--padding-xs);
    padding-bottom: var(--padding-m);
    font-size: 1rem;
    font-weight: 600;
    color: var(--r2e-secondary-200)
  }

}

[data-theme='dark'] {
  ion-modal {
    --background: var(--r2e-secondary);
  }

  div.modal-header {
    h3 {
      color: var(--r2e-white);
      svg {
        fill: var(--r2e-white);
      }
    }
  }

  div.modal-main {
    .toggle-group {
      color: var(--r2e-white);

      span {
        color: var(--r2e-white);
      }

    }

  }

  div.modal-footer {
    button.r2e-button {
      background-color: var(--r2e-primary);
      color: var(--r2e-white);
    }

    span {
      color: var(--r2e-secondary-200);
    }
  }

  div.app-version {
    color: var(--r2e-secondary-200);
  }
}
</style>
