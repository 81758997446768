import session from "@/modules/session";
import Address from "@/resources/Address";
import {goTo} from "@/modules/global/helpers/routerHelper";
function setAddress(data){
  let address = new Address();
  address.street = data.route;
  address.bus = data.subpremise ? data.subpremise : data.bus;
  address.city.name = data.locality;
  address.city.zip = data.postal_code;
  address.longitude = data.longitude;
  address.latitude = data.latitude;

  if (data.street_number) {
    address.number = data.street_number;
  }

  saveAddress(address);

  return address;
}

function saveAddress(address){
  session.commit('setAddress', address);
  localStorage.setItem('last-used-address', JSON.stringify(address));
}

function setAddressByModel(address){
  session.commit('setAddress', address);
  localStorage.setItem('last-used-address', JSON.stringify(address));
}

function getAddress() {
  return session.getters.getAddress;
}

function getAddressString(address){
  return address.street + (address.number ? " " + address.number: "") + (address.bus ? " " + address.bus: "") +
  ", " + (address.city.zip ? address.city.zip + " " : null) + address.city.name
}

function getFullAddressString(address){
  if(address){
    return address.street + (address.number ? " " + address.number: "") + (address.bus ? " " + address.bus: "") +
      ", " + address.city.zip + " " + address.city.name
  }
}

function getLastUsedAddress() {
  const lastUsedAddress = localStorage.getItem('last-used-address');
  return lastUsedAddress ? JSON.parse(lastUsedAddress) : null;
}

function validateAddressFields(){
  const address = session.getters.getAddress;

  if(!address){
    return goTo('catalogue');
  }

  return address.street !== null && address.street !== '' &&
    address.number !== null && address.number !== '' &&
    address.city.name !== null && address.city.name !== '' &&
    address.city.zip !== null && address.city.zip !== '';
}

function setAddressFromGoogleLocation(data, lat, lng){
  let address = new Address();

  address.street = data.find((component) => component.types.includes("route"))?.long_name || null;
  address.number = data.find((component) => component.types.includes("street_number"))?.long_name || null;
  address.bus = data.find((component) => component.types.includes("subpremise"))?.long_name || null;
  address.latitude = lat;
  address.longitude = lng;
  address.city.name = data.find((component) => component.types.includes("locality"))?.long_name || null;
  address.city.zip = data.find((component) => component.types.includes("postal_code"))?.long_name || null;
  address.city.province = data.find((component) => component.types.includes("administrative_area_level_2"))?.long_name || null;
  address.city.country = data.find((component) => component.types.includes("country"))?.long_name || null;
  address.city.country_code = data.find((component) => component.types.includes("country"))?.short_name || null;

  session.commit('setAddress', address);
  localStorage.setItem('last-used-address', JSON.stringify(address));
}

export {
  setAddress,
  setAddressByModel,
  getAddress,
  getLastUsedAddress,
  getAddressString,
  getFullAddressString,
  validateAddressFields,
  setAddressFromGoogleLocation
}
