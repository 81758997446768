import {Capacitor} from "@capacitor/core";

export default class PlatformService {
  static isNative() {
    return Capacitor.isNativePlatform();
  }

  static isIOS() {
    return Capacitor.getPlatform() === 'ios';
  }

  static isAndroid() {
    return Capacitor.getPlatform() === 'android';
  }

  static isWeb() {
    return Capacitor.getPlatform() === 'web';
  }

  static isProduction() {
    return process.env.APP_ENV === 'production';
  }

  static isStaging() {
    return process.env.APP_ENV === 'staging';
  }

  static isLocal() {
    return process.env.APP_ENV === 'local';
  }
}
