/* eslint-disable */
import {createApp, h} from "vue";
import {__t} from "@/globals";
import router from "@/router";
import routerHelper from "@/configuration/router";
import ModalNavigation from "@/modules/navigation/ModalNavigation.vue";
import ModalArticle from "@/modals/ModalArticle.vue";
import ModalDeliveryTime from "@/modals/ModalDeliveryTime.vue";
import ModalPaymentMethod from "@/modals/ModalPaymentMethod.vue";
import ModalReady2Help from "@/modals/ModalReady2Help.vue";
import ModalDiscountCode from "@/modals/ModalDiscountCode.vue";
import ModalSubmitOrder from "@/modals/ModalSubmitOrder.vue";
import ModalComment from "@/modals/ModalComment.vue";
import ModalNotification from "@/modals/ModalNotification.vue";
import ModalEstablishmentInfo from "@/modals/ModalEstablishmentInfo.vue";
import ModalLoading from "@/modals/ModalLoading.vue";
import ModalContactFields from "@/modals/ModalContactFields.vue";
import {modalController} from "@ionic/vue";
import ModalSearchLocation from "@/modals/ModalSearchLocation.vue";
import ModalToken from "@/modals/ModalToken.vue";
import {getAddress} from "@/modules/order/services/AddressService.js";
import ModalRegisteringCode from "@/modals/ModalRegisteringCode.vue";
import ModalUpdateVersion from "@/modals/ModalUpdateVersion.vue";
import ModalDeleteAccount from "@/modals/ModalDeleteAccount.vue";

export function openLoadingModal() {
  const modal = createApp({
    setup() {
      return () => h(ModalLoading, {
        onClose: () => {
          unMount(modal);
        }
      })
    }
  }).use(__t, router, routerHelper);

  mountToBody(modal);
}


export async function openNavigationModal() {
  const modal = await modalController.create({
    component: ModalNavigation,
  });

  await modal.present();
}

export async function openSearchLocationModal() {
  const modal = await modalController.create({
    component: ModalSearchLocation,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    cssClass: 'ion-modal-search-initial'
  });

  await modal.present();
}

export async function openArticleModal(params) {
  if(!getAddress()){
    await openSearchLocationModal();
    return;
  }

  const modal = await modalController.create({
    component: ModalArticle,
    componentProps: {
      articleId: params.articleId,
      establishmentSlug: params.establishmentSlug,
    },
    cssClass: 'ion-modal-article'
  });

  await modal.present();
}

export async function openDeliveryTimeModal() {
  const modal = await modalController.create({
    component: ModalDeliveryTime,
    initialBreakpoint: 0.5,
    breakpoints: [0, 0.5, 1],
    cssClass: 'ion-modal-mini ion-modal-delivery',

  });

  await modal.present();
}

export async function openTakeAwayTimeModal() {
  const modal = await modalController.create({
    component: ModalDeliveryTime,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    cssClass: 'ion-modal-mini'
  });

  await modal.present();
}

export async function openPaymentMethodModal() {
  const modal = await modalController.create({
    component: ModalPaymentMethod,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    cssClass: 'ion-modal-mini ion-modal-mini-payment'
  });

  await modal.present();
}

export async function openReady2HelpModal() {
  const modal = await modalController.create({
    component: ModalReady2Help,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    cssClass: 'ion-modal-mini ion-modal-ready2help'
  });

  await modal.present();
}

export async function openDiscountCodeModal() {
  const modal = await modalController.create({
    component: ModalDiscountCode,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    cssClass: 'ion-modal-mini ion-modal-discount-code'
  });

  await modal.present();
}

export async function openSubmitOrderModal() {
  const modal = await modalController.create({
    component: ModalSubmitOrder,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    cssClass: 'ion-modal-mini'
  });

  await modal.present();
}

export async function openCommentModal(params) {
  const modal = await modalController.create({
    component: ModalComment,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    componentProps: params,
    cssClass: 'ion-modal-mini'
  });

  await modal.present();
}

export async function openNotificationModal(params) {
  const modal = await modalController.create({
    component: ModalNotification,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    componentProps: params,
    cssClass: 'ion-modal-mini ion-modal-notification'
  });

  await modal.present();
}

export async function openEstablishmentInfo(params) {
  const modal = await modalController.create({
    component: ModalEstablishmentInfo,
    componentProps: {
      establishment: params.establishment,
    },
    cssClass: 'modal-establishment-info'
  });

  await modal.present();
}

export async function openContactFieldsModal() {
  const modal = await modalController.create({
    component: ModalContactFields,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    cssClass: 'ion-modal-mini ion-modal-mini-contact'
  });

  await modal.present();
}

export async function openRegisteringCodeModal() {
  const modal = await modalController.create({
    component: ModalRegisteringCode,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    cssClass: 'ion-modal-mini ion-modal-register-code'
  });

  await modal.present();

}

export async function openTokenModal() {
  const modal = await modalController.create({
    component: ModalToken,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    cssClass: 'ion-modal-mini ion-modal-token'
  });

  await modal.present();

}

export async function openDeleteAccountModal() {
  const modal = await modalController.create({
    component: ModalDeleteAccount,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    cssClass: 'ion-modal-mini ion-modal-delete-account'
  });

  await modal.present();
}

export async function openUpdateVersionModal(params) {
  const modal = await modalController.create({
    component: ModalUpdateVersion,
    initialBreakpoint: 1,
    breakpoints: [0, 1],
    cssClass: 'ion-modal-mini ion-modal-update-version',
    backdropDismiss: false,
    componentProps: {
      version: params.version
    },
    handle: false
  });

  await modal.present();
}

function mountToBody(modal){
  const wrap = document.createElement('div');

  document.body.appendChild(wrap);

  document.body.classList.add('modal-open');
  document.documentElement.classList.add('modal-open');

  modal.mount(wrap);

  wrap.remove();
}

function unMount(modal){
  document.body.classList.remove('modal-open');
  document.documentElement.classList.remove('modal-open');

  modal.unmount();
}
