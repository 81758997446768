import { initializeApp } from "firebase/app";

export default class FirebaseWebConfig {
  constructor() {
    this.config = {
      apiKey: "AIzaSyAKOXTzd42hwMtZKFoEvJ-Y9XGE0Y_TLp0",
      authDomain: "ready2eat-97e01.firebaseapp.com",
      projectId: "ready2eat-97e01",
      storageBucket: "ready2eat-97e01.appspot.com",
      messagingSenderId: "757123779988",
      appId: "1:757123779988:web:7a6b9a91b9bd8759385381",
      measurementId: "G-WLJY59CZT0"
    };
  }

  app() {
    return initializeApp(this.config, 'Ready2Eat - Customer');
  }
}
