<template>
  <!--  <smooth-corners
    class="button-wrapper"
    :class="{'add' : type === 'add'}"
    :bg-color="smoothBgColor"
  >-->
  <component
    :is="el"
    class="r2e-button"
    :class="[
      {'add': type === 'add'},
      {'disabled': disabled},
      `color--${color}`,
      `type--${type}`
    ]"
  >
    <Icon v-if="type === 'add'" name="add" />
    <Icon v-else-if="icon" :name="icon" />
    <slot />
  </component>
<!--  </smooth-corners>-->
</template>

<script>
  import Icon from "@/ui/icon/Icon.vue";
  import SmoothCorners from "@/modules/global/SmoothCorners.vue";

  export default {
    name: "R2eButton",
    components: {SmoothCorners, Icon},
    props: {
      el: {type: String, required: false, default: 'button'},
      color: {type: String, required: false, default: 'black'},
      smoothBgColor: {type: String, required: false, default: 'var(--ion-background-color)'},
      type: {type: String, required: false, default: 'default'},
      icon: {type: String, required: false, default: null},
      disabled: {type: Boolean, required: false, default: false},
    }
  }
</script>

<style lang="scss">
.button-wrapper {
  transition: all ease 200ms;
  width: 100%;
  max-width: 40rem;
  margin: auto;

  &:hover {
    transform: scale(1.02);
  }

  &.add {
    width: 4.6rem;
    height: 3.9rem;
  }
}

.r2e-button {
  border-radius: 1rem;
  background-color: var(--r2e-secondary);
  width: 100%;
  height: 6.3rem;
  display: flex;
  max-width: none;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  transition: all ease 200ms;

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &.color--black {
    background-color: var(--r2e-secondary);
    color: #fff;

    svg {
      width: 1.4rem;
      height: 1.45rem;
      margin-right: var(--margin-s);
      color: #fff;
      fill: #fff;
    }
  }

  &.color--white {
    background-color: #fff;
    color: #272D2D;
  }

  &.type--add {
    width: 4.6rem;
    height: 3.9rem;
    border-radius: 1rem;
    background-color:  var(--r2e-secondary);

    svg {
      width: 1rem;
      height: 1rem;
      margin-left: auto;
      margin-right: auto;
      color: #fff;
      fill: #fff;
    }
  }
}

[data-theme='dark'] {
  .r2e-button {
    background-color: var(--r2e-secondary-600);

    &.color--black {
      background-color: var(--r2e-secondary-600);
    }

    &.type--add {
      background-color: var(--r2e-secondary-600);
    }
  }

  &.secondary-bg {
    background-color: var(--r2e-secondary);
  }
}
</style>

