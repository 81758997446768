// filter.js
import {__t} from "@/globals";
import {reactive, toRefs} from "vue";

const initialState = {
  toggles: {
    delivery: true,
    open: false,
    ready2help: false,
  },
  types: {
    all: true,
    restaurant: false,
    shop: false,
    night_shop: false,
  },
  categories: [
    {key: 'all', active: true},
    {key: 'kebab', active: false},
    {key: 'pizza', active: false},
    {key: 'burgers', active: false},
    {key: 'pasta', active: false},
    {key: 'asian', active: false},
    {key: 'sushi', active: false},
    {key: 'snacks', active: false},
    {key: 'drinks', active: false},
    {key: 'sandwiches', active: false},
    {key: 'fries', active: false},
    {key: 'groceries', active: false},
  ]
}

export const useFilter = () => {
  const state = reactive({ ...initialState });

  const updateFilter = (category, key, value) => {
    if (category === 'toggles' || category === 'types') {
      state[category][key] = value;
      if(category === 'types'){
        if(key === 'all'){
          for (let key in state.types) {
            state.types[key] = false;
          }

          state.types.all = true;
        } else {
          if(value){
            state.types.all = false;
          }

          else if(!value){
            if(Object.values(state.types).every(value => value === false)){
              state.types.all = true;
            }
          }
        }


      }

    } else if (category === 'categories') {
      if(key === 'all'){
        state.categories.forEach(cat => cat.active = false);
        state.categories.find(cat => cat.key === 'all').active = true;
      }else {
        state.categories.find(c => c.key === key).active = value;
        state.categories.find(cat => cat.key === 'all').active = false;

        if (!value) {
          state.categories.find(cat => cat.key === 'all').active =
            state.categories.findIndex(cat => cat.active === true) === -1;
        }
      }
    }
  };

  const resetFilter = () => {
    Object.assign(state, initialState);
  };

  const setFilterState = (newState) => {
    Object.assign(state, newState);
  };

  const getFilter = () => {
    return {
      ready2help: state.toggles.ready2help,
      open: state.toggles.open,
      delivery: state.toggles.delivery,
      types: Object.keys(state.types).filter(key => state.types[key] === true),
      categories: state.categories.filter(c => c.active).map(c => c.key),
    };
  };

  return { ...toRefs(state), updateFilter, getFilter, setFilterState, resetFilter };
};
