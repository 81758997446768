<template>
  <app-modal-mini
    :button-disabled="disabled"
    :submit-txt="!delivery ? __t('checkout.validation.notification.submit'): null"
    @submitted="submit"
  >
    <template #header>
      <h3 v-if="method">
        <Icon :name="method.value === true ? 'card' : 'cash'" />
        {{ __t('checkout.payment-method.title') }}
      </h3>
    </template>

    <template #main>
      <Select
        :disabled="!delivery"
        :selected="method"
        :options="options"
        label="title"
        @setter="val => setMethod(val)"
      />

      <div class="providers">
        <template v-if="method.value === true">
          <template v-if="!delivery">
            <p v-html="__t('checkout.payment-method.online-no-cash.body')" />
            <p v-html="__t('checkout.payment-method.online.body')" />
          </template>

          <p v-else v-html="__t('checkout.payment-method.online.body')" />

          <div class="list">
            <Icon name="kbc" />
            <Icon name="belfius" />
            <Icon name="bancontact" />
            <Icon name="mastercard" />
            <Icon name="visa" />
          </div>
        </template>
      </div>
    </template>
  </app-modal-mini>
</template>

<script>
  import AppModalMini from "@/modals/AppModalMini.vue";
  import {$showToast, __t} from "@/globals";
  import {Icon, Select} from "@/ui";
  import session from "@/modules/session";
  import {modalController} from "@ionic/vue";

  export default {
    name: "ModalDeliveryTime",
    components: {Icon, AppModalMini, Select},
    data() {
      return {
        preferred_time_of_arrival: null,
        disabled: true,
        method: null,
        options: []
      }
    },
    computed: {
      online_payment() {
        return session.getters['order/online_payment'];
      },
      delivery() {
        return session.getters['cart/delivery'];
      }
    },
    beforeMount() {
      this.setPaymentMethods();

      this.method = this.online_payment ? this.options[0] : this.options[1];
    },
    methods: {
      __t,
      setMethod(method) {
        this.method = method;
        this.disabled = false;
      },
      setPaymentMethods() {
        if (this.delivery) {
          this.options = [
            {value: true, title: __t('checkout.payment-method.online')},
            {value: false, title: __t('checkout.payment-method.cash')},
          ];
        } else {
          this.disabled = false;
          this.options = [
            {value: true, title: __t('checkout.payment-method.card')},
          ]
        }

      },
      submit() {
        session.commit('order/setOnlinePayment', this.method.value);
        $showToast(__t('success.checkout.payment-method'), 'success');
        modalController.dismiss();
      }
    },
  }
</script>

<style lang="scss">
.ion-modal-mini.ion-modal-mini-payment {
  --height: 53rem;
}

.providers {
  max-width: 38rem;
  margin: auto;

  p {
    text-align: center;
    margin: var(--margin-m) 0;
    font-weight: 600;
    color: var(--r2e-secondary-200);
    font-size: 1.1rem;
  }

  .list {
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 24.7rem;

    svg {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
}
</style>
