<template>
  <ion-header />
  <ion-content class="ion-padding content-radius" overflow-scroll="false">
    <div class="inner-content">
      <div class="modal-header" />
      <div class="modal-main mb-l">
        <div class="mb-m">
          <p>{{ __t('app.modal.token.body') }}</p>
        </div>
        <div class="modal-footer">
          <Button
            class="mt-m"
            @click="submitLogin"
          >
            {{ __t('app.modal.token.button') }}
          </Button>
        </div>
      </div>
    </div>
  </ion-content>
</template>

<script setup>
  import {__t} from '@/globals';
  import {modalController, IonHeader, IonContent} from "@ionic/vue";
  import {Button} from "@/ui";
  import {goTo} from "@/modules/global/helpers/routerHelper.js";

  const submitLogin = () => {
    goTo('login');

    modalController.dismiss();
  }


</script>


<style lang="scss">
.ion-modal-token {
  --height: 32rem;

  .modal-main {
    margin: auto auto var(--margin-l);
    max-width: 38rem;

    p {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--r2e-secondary-200);
      line-height: 1.7;
    }
  }

  .modal-footer {
    max-width: 36rem;
    margin: auto auto var(--margin-l);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error-message {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 var(--margin-s);
    height: 3rem;
    background-color: var(--r2e-primary-100);
    margin: var(--margin-m) auto;
    font-size: 1rem;
    font-weight: 500;
    color: var(--r2e-error);

    max-width: 36rem;

    @media(min-width: 1080px) {
      max-width: 46rem;
    }
  }
}

[data-theme='dark'] {
  .ion-modal-search-initial {
    h3 {
      color: var(--r2e-white);

      svg {
        fill: var(--r2e-white);
      }
    }

    .modal-footer {
      button {
        background-color: var(--r2e-primary);
        color: var(--r2e-white);
      }
    }
  }
}

</style>
