<template>
  <ion-header />
  <ion-content class="ion-padding content-radius" overflow-scroll="false">
    <div class="inner-content">
      <div class="modal-header" />
      <div class="modal-main mb-l">
        <div class="mb-m">
          <p>{{ __t('auth.registering-code.title') }}</p>
        </div>

        <r2e-reset-code
          :loading="loading"
          @code-entered="submitCode"
        />
      </div>
    </div>
  </ion-content>
</template>

<script setup>
  import {computed, ref} from 'vue';
  import {$showToast, __t} from '@/globals';
  import {modalController, IonHeader, IonContent} from "@ionic/vue";
  import axios from "axios";
  import {apiUrl} from "@/configuration/api.js";
  import session from "@/modules/session.js";
  import R2eResetCode from "@/ui/resetcode/R2eResetCode.vue";

  const loading = ref(false);
  const email = computed(() => session.getters["user/registering_email"]);
  const password = computed(() => session.getters["user/registering_password"]);

  const submitCode = async (code) => {
    loading.value = true;

    await axios.post(apiUrl('verify-order-register-code'), {
      register_code: code,
      email: email.value
    }).then(() => {
      $showToast(__t('success.verify-code.order'), 'success', 3000);

      setTimeout(async () => {
        await login();
      }, 3000);

    }).catch(() => {
      $showToast(__t('error.verify-code.order'), 'error', 5000);
    }).finally(() => {
      loading.value = false;
    });
  };

  const login = async () => {
    const loginPayload = {
      email: email.value,
      password: password.value
    };

    try {
      const tokenResponse = await axios.post(`${process.env.API_URL}/oauth/token`, {
        grant_type: 'password',
        client_id: 2,
        client_secret: process.env.API_KEY_SECRET,
        username: loginPayload.email,
        password: loginPayload.password
      });

      const token = tokenResponse.data.access_token;
      const expires = tokenResponse.data.expires_in;

      const userResponse = await axios.get(`${process.env.API_URL}/v1/user`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      const user = userResponse.data.data;

      session.commit('user/setUser', user);
      session.commit('user/setToken', token);
      session.commit('user/setTokenExpiry', expires);

      session.commit('user/setRegisteringEmail', null);
      session.commit('user/setRegistering', false);
      session.commit('user/setRegisteringPassword', null);

      $showToast(__t('success.login'), 'success');

      await modalController.dismiss();
    } catch (error) {
      $showToast(__t('error.login'), 'error');
    }

  };
</script>


<style lang="scss">
.ion-modal-register-code {
  .modal-main {
    margin: auto auto var(--margin-l);
    max-width: 40rem;

    p {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--r2e-secondary-200);
      line-height: 1.7;
    }
  }

  .modal-footer {
    max-width: 36rem;
    margin: auto auto var(--margin-l);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error-message {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 var(--margin-s);
    height: 3rem;
    background-color: var(--r2e-primary-100);
    margin: var(--margin-m) auto;
    font-size: 1rem;
    font-weight: 500;
    color: var(--r2e-error);

    max-width: 36rem;

    @media(min-width: 1080px) {
      max-width: 46rem;
    }
  }
}

[data-theme='dark'] {
  .ion-modal-search-initial {
    h3 {
      color: var(--r2e-white);

      svg {
        fill: var(--r2e-white);
      }
    }

    .modal-footer {
      button {
        background-color: var(--r2e-primary);
        color: var(--r2e-white);
      }
    }
  }
}

</style>
