<template>
  <button class="logout-button" @click="logout">
    {{ __t('app.logout') }}
  </button>
</template>

<script setup>
  import { defineEmits } from 'vue';
  import { __t } from "@/globals";

  const emit = defineEmits(['logout']);

  function logout() {
    emit('logout');
  }
</script>

<style lang="scss">
button.logout-button {
  margin: var(--margin-l) auto;
  margin-bottom: 5.6rem;
  background-color: transparent;
  min-width: 10rem;
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--r2e-secondary-200);
}
</style>
