<template>
  <div class="auth-code-container">
    <template v-if="!loading">
      <input
        v-for="(input, index) in inputs"
        :key="index"
        ref="inputRefs"
        type="tel"
        class="auth-code-input"
        maxlength="1"
        @input="onInput($event, index)"
        @paste="onPaste($event, index)"
      >
    </template>

    <div v-else class="input-loading-animation">
      <icon name="loader" />
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, defineEmits, onUnmounted } from 'vue';
  import Icon from "@/ui/icon/Icon.vue";
  import {onIonViewDidEnter} from "@ionic/vue";

  const inputs = ref(['', '', '', '', '', '']);
  const inputRefs = ref([]);
  const emit = defineEmits(['codeEntered']);

  // eslint-disable-next-line no-undef
  defineProps({
    loading: {
      type: Boolean,
      default: false
    }
  });

  onIonViewDidEnter(() => {
    inputs.value = ['', '', '', '', '', ''];
  });

  const focusNextInput = (index) => {
    if (index < inputRefs.value.length - 1) {
      inputRefs.value[index + 1].focus();
    }
  };

  const onInput = (event, index) => {
    const value = event.target.value.replace(/\D/g, '');
    inputs.value[index] = value;

    if (value.length === 1) {
      focusNextInput(index);
    }

    if (checkIfAllInputsFilled()) {
      emit('codeEntered', inputs.value.join(''));
    }
  };

  const checkIfAllInputsFilled = () => {
    return inputs.value.every((input) => input.length === 1);
  };

  const onPaste = (event, index) => {
    const pasteData = event.clipboardData.getData('text').trim().replace(/\D/g, ''); // Get only digits from pasted data

    if (index === 0) {
      const characters = pasteData.split('').slice(0, inputs.value.length);
      characters.forEach((char, i) => {
        inputs.value[i] = char;
        inputRefs.value[i].value = char;
      });
      inputRefs.value[characters.length - 1].focus();
    } else {
      const firstChar = pasteData[0] || '';
      inputs.value[index] = firstChar;
      inputRefs.value[index].value = firstChar;
      focusNextInput(index);
    }

    event.preventDefault();

    if (checkIfAllInputsFilled()) {
      emit('codeEntered', inputs.value.join(''));
    }
  };

  onMounted(() => {
    inputRefs.value[0].focus();
  });

  onUnmounted(() => {
    inputRefs.value = ['', '', '', '', '', ''];
  });
</script>



<style lang="scss">
.auth-code-container {
  display: flex;
  width: 100%;
  justify-content: center;
  position: relative;
  gap: var(--margin-s);
  min-height: 6.1rem;

  .input-loading-animation {
    position: absolute;
    width: 100%;
    z-index: 3;
    height: 100%;
    display: flex;
    justify-content: center;
    transform: scale(1.5);

    svg {
      width: 3rem;
      height: 3rem;
      color: var(--r2e-secondary);
    }
  }
}

.auth-code-input {
  width: 6.1rem;
  height: 6.1rem;
  text-align: center;
  background-color: var(--r2e-secondary-100);
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  transition: var(--effect);
  font-weight: 700;


  &:active, &:focus {
    border: 1px solid var(--r2e-secondary);
    outline: var(--r2e-secondary);
  }

  @media (max-width: 450px) {
    width: 4rem;
    height: 4rem;
    font-size: 1.4rem;
  }

  @media (max-width: 300px) {
    width: 3rem;
    height: 3rem;
    font-size: 1.4rem;
  }
}

[data-theme='dark'] {
  .auth-code-input {
    background-color: var(--r2e-secondary-500);
    color: var(--r2e-white);
  }

  .auth-code-container {
    .input-loading-animation {
      svg {
        color: var(--r2e-white);
      }
    }
  }
}
</style>
