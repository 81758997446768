<template>
  <div class="r2e-textarea" :class="[{'error' : error }]">
    <label v-if="label" :for="`input-${id}`">{{ label }}</label>
    <div class="input-field">
      <textarea
        :id="`input-${id}`"
        :name="id"
        :value="value"
        :placeholder="placeholder"
        @input="
          $emit('set', $event.target.value);
          $emit('removeError');
        "
        @focusout="$emit('focusOut', $event.target.value)"
      />
    </div>
    <span v-if="error" class="error">
      {{ error }}
    </span>
  </div>
</template>

<script>
  export default {
    name: "R2eTextarea",
    props: {
      id: { type: String, required: true },
      placeholder: { type: String, required: false, default: 'text'},
      label: { type: String, required: false, default: null},
      value: { type: undefined, required: false, default: null },
      error: { type: String, required: false, default: null },
    },
    emits: ['removeError', 'set', 'focusOut'],

  }
</script>

<style lang="scss">
.r2e-textarea{
  label {
    font-size: 1.2rem;
    font-weight: 600;
    color: #272D2D;
  }

  div.input-field textarea {
    width: 100%;
    background-color: var(--r2e-secondary-100);
    border: 1px solid var(--r2e-secondary-100);
    border-radius: 4px;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    padding: 1.9rem 1.6rem;
    resize: vertical;

    font-weight: 500;
    color: var(--r2e-secondary);

    &::placeholder {
      color: var(--r2e-secondary-200);
      opacity: 1; /* Firefox */
      font-size: 1.2rem;
    }

    &::-ms-input-placeholder { /* Edge 12 -18 */
      color: var(--r2e-secondary-200);
    }
  }

  span.error {
    color: #D50043;
    font-size: 1.2rem;
  }

  &.error {
    div.input-field input {
      background-color: #FBE8EE;
      color: #D50043;
    }
  }
}

// dark mode
[data-theme='dark'] {
  .r2e-textarea {
    label {
      color: var(--r2e-white);
    }

    div.input-field textarea {
      background-color: var(--r2e-secondary-500);
      border: 1px solid var(--r2e-secondary-200);
      color: var(--r2e-white);

      &::placeholder {
        color: var(--r2e-secondary-200);
      }
    }
  }
}
</style>
