<template>
  <div class="nav-header" :class="{'native': native}">
    <container>
      <div class="profile-section">
        <div class="avatar">
          <ProfileAvatar />
        </div>
        <div class="info">
          <template v-if="!authenticated">
            <h2>{{ __t('nav.welcome') }}</h2>
            <a @click.prevent="navigate('login')">{{ __t('nav.login-link') }}</a>
          </template>
          <template v-else>
            <h2>{{ __t('nav.welcome') }} {{ getFullName(user) }}</h2>
            <a @click.prevent="navigate('account')">{{ __t('nav.profile') }}</a>
          </template>
        </div>
      </div>
      <button class="btn-exit" @click="close">
        <Icon name="exit" />
      </button>
    </container>
  </div>
</template>

<script setup>
  import {defineProps, defineEmits, computed} from 'vue';
  import Icon from "@/ui/icon/Icon.vue";
  import ProfileAvatar from "@/modules/profile/parts/ProfileAvatar.vue";
  import Container from "@/layout/layout/Container.vue";
  import {__t} from "@/globals.js";
  import session from "@/modules/session.js";
  import {getFullName} from "@/modules/auth/services/UserService.js";
  import PlatformService from "@/services/PlatformService.js";

  defineProps({
    fullName: {
      type: String,
      default: '',
    },
  });

  const authenticated = computed(() => session.getters['user/isAuthenticated']);

  const user = computed(() => session.getters['user/user']);

  const emit = defineEmits(['close', 'navigate']);

  const native = PlatformService.isNative();

  function close() {
    emit('close');
  }

  function navigate(route) {
    emit('navigate', route);
  }
</script>

<style lang="scss">
.nav-header {
  padding-bottom: var(--padding-m);

  .container {
    display: flex;
    align-items: center;
  }


  div.profile-section {
    display: flex;
    align-items: center;

    div.avatar {
      width: 3rem;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;

      i svg {
        fill: #fff;
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    div.info {
      margin-left: var(--margin-s);
      display: flex;
      justify-content: center;
      flex-direction: column;

      h2 {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1;
        text-transform: capitalize;
        margin-bottom: 4px
      }

      a {
        font-size: 1rem;
        line-height: 1;
        font-weight: 600;
        text-transform: lowercase;
        color: var(--r2e-primary);
      }
    }
  }

  button.btn-exit {
    background-color: transparent;
    margin-bottom: auto;
    margin-left: auto;
    width: 1.4rem;
    height: 1.4rem;
    transition: var(--effect);

    svg {
      width: 1.4rem;
      height: 1.4rem;
      fill: var(--color-black);
    }

    &:hover {
      transform: scale(1.08);
    }
  }

  @media(min-height: 600px) {
    div.profile-section {

      div.avatar {
        width: 5.6rem;
        height: 5.6rem;

        i svg {
          fill: #fff;
          width: 2.8rem;
          height: 2.8rem;
        }
      }

      div.info {
        margin-left: var(--margin-m);
        display: flex;
        justify-content: center;
        flex-direction: column;

        h2 {
          font-size: 1.6rem;
          font-weight: 600;
          line-height: 1;
          text-transform: capitalize;
          margin-bottom: var(--margin-xs);
        }

        a {
          padding: 0.2rem 1rem;
          border: 2px solid var(--r2e-primary);
          width: max-content;
          border-radius: 99rem;
          font-size: 1rem;
          line-height: 1;
          font-weight: 600;
          text-transform: lowercase;
          color: var(--r2e-primary);
        }
      }
    }
  }
}

[data-theme='dark'] {
  .nav-header {
    background-color: var(--r2e-secondary-600);

    .btn-exit {
      svg {
        fill: var(--r2e-secondary-300);
      }
    }

    .profile-section {
      div.avatar {

      }

      div.info {
        h2 {
          color: var(--r2e-white);
        }
      }
    }
  }
}
</style>
