const OrderStatusEnum = {
  QUEUING: 'queuing',
  CONFIRMED: 'confirmed',
  PAID: 'paid',
  IN_PREPARATION: 'in_preparation',
  READY_TO_GO: 'ready_to_go',
  IN_DELIVERY: 'in_delivery',
  COMPLETE: 'complete',
  ON_HOLD: 'on_hold',
  CANCELED: 'canceled'
};

export default OrderStatusEnum;
