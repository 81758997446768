<template>
  <template v-if="only_takeout">
    <span class="toggle-delivery only-takeaway">
      <Icon name="info" />
      <span>
        {{ __t('app.no-delivery') }}
      </span>
    </span>
  </template>
  <template v-else>
    <span class="toggle-delivery">
      <template v-if="delivery">
        <b class="active">{{ __t('app.delivery') }}</b>
      </template>
      <template v-else>
        <b>{{ __t('app.takeaway') }}</b>
      </template>


      <Toggle class="delivery-toggle" :active="delivery" @toggle="toggleDelivery" />

      <Tooltip position="left">{{ __t('app.no-delivery') }}</Tooltip>
    </span>
  </template>
</template>

<script>
  import {Toggle} from "@/ui";
  import {__t} from "@/globals";
  import {toggleDelivery} from "@/modules/order/services/CartService";
  import session from "@/modules/session";
  import Tooltip from "@/modules/global/parts/Tooltip.vue";
  import Icon from "@/ui/icon/Icon.vue";

  export default {
    name: "DeliveryToggle",
    components: {Icon, Tooltip, Toggle},
    computed: {
      delivery(){
        return session.getters['cart/delivery'];
      },
      only_takeout() {
        const establishment = session.getters['catalogue/currentEstablishment'];

        if(establishment){
          return establishment.delivery === 'takeout';
        }

        return false;
      }
    },
    methods: {toggleDelivery, __t}
  }
</script>

<style lang="scss">
.toggle-delivery {
  display: flex;
  font-size: 1.2rem;
  align-items: center;

  &.only-takeaway {
    svg {
      width: 1.8rem;
      height: 1.8rem;
      color: var(--r2e-primary);
      margin-right: var(--margin-xs);
    }

    span {
      font-size: 1rem;
      max-width: 20rem;
      font-weight: 600;
      color: var(--r2e-primary)
    }
  }

  &.disabled {

    position: relative;

    .toggle, b {
      opacity: 0.3;
      pointer-events: none;
    }

    &:hover {
      .tooltip {
        display: flex;
        right: 20%;
      }
    }
  }

  b {
    margin-right: var(--margin-xs);
    font-weight: 600;

    &.active {
      color: var(--r2e-primary);
    }
  }
}
</style>
