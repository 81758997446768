<template>
  <div class="r2e-input" :class="{ error: error }">
    <label v-if="label" :for="`input-${id}`">{{ label }}</label>
    <div class="input-field" :class="{ disabled: disabled }">
      <input
        :id="`input-${id}`"
        :name="name ? name : id"
        :type="textType"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        :pattern="pattern"
        @input="
          emit('set', $event.target.value);
          emit('removeError');
        "
        @focusout="emit('focusOut', $event.target.value)"
      >
      <span
        v-if="type === 'password'"
        tabindex="-1"
        class="password-show"
        @click.prevent="toggleShowPassword"
      >
        <Icon v-if="!showPassword" name="eye-closed" />
        <Icon v-else name="eye" />
      </span>
    </div>
    <span v-if="error" class="error">{{ error }}</span>
  </div>
</template>

<script setup>
  import {defineProps, defineEmits, ref} from 'vue';
  import Icon from "@/ui/icon/Icon.vue";

  const props = defineProps({
    id: { type: String, required: true },

    type: { type: String, default: 'text' },
    placeholder: { type: String, default: '' },
    label: { type: String, default: null },
    value: { type: [String, Number], default: null },
    name: { type: String, required: false, default: null },
    error: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    pattern: { type: String, default: null },
  });

  const showPassword = ref(false);

  const textType = ref(props.type);

  function toggleShowPassword() {
    showPassword.value = !showPassword.value;

    if(props.type === 'password'){
      textType.value = showPassword.value ? 'text' : 'password';
    }
  }

  const emit = defineEmits(['removeError', 'set', 'focusOut']);
</script>

<style lang="scss">
.r2e-input {
  label {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.6;
    color: var(--r2e-secondary);
    padding-bottom: 5px;
  }

  .input-field.disabled {
    opacity: 0.3;
  }

  .input-field input {
    width: 100%;
    background-color: var(--r2e-secondary-100);
    border: 1px solid transparent;
    border-radius: 4px;
    height: 6.1rem;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    padding: 1.9rem 1.6rem;
    transition: var(--effect);
    font-weight: 500;
    color: var(--r2e-secondary);

    &::placeholder {
      color: var(--r2e-secondary-200);
      opacity: 1;
      font-weight: 400;
    }

    &::-ms-input-placeholder {
      color: var(--r2e-secondary-200);
    }

    &:hover {
      border: 1px solid var(--r2e-secondary-200);
    }
  }

  .input-field {
    position: relative;

    span.password-show {
      position: absolute;
      right: var(--margin-m);
      top: calc(50% - 1.4rem);
      width: 2.8rem;
      height: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      svg {
        color: var(--r2e-secondary-200);
        width: 2rem;
        height: 2rem;
      }
    }
  }

  span.error {
    color: var(--r2e-error);
    font-size: 1.2rem;
  }

  &.error .input-field input {
    background-color: var(--r2e-primary-100);
    color: var(--r2e-error);
  }
}

[data-theme='dark'] {
  .r2e-input {
    label {
      color: var(--r2e-secondary-200);
    }

    .input-field input {
      background-color: var(--r2e-secondary-500);
      color: var(--r2e-white);

      &::placeholder {
        color: var(--r2e-secondary-200);
      }

      &::-ms-input-placeholder {
        color: var(--r2e-secondary-200);
      }
    }

    span.error {
      color: var(--r2e-error);
    }

    &.error .input-field input {
      background-color: var(--r2e-primary-700);
      color: var(--r2e-error);
    }
  }
}
</style>
