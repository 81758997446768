import {createStore} from 'vuex';
import user from "@/modules/auth/user";
import catalogue from "@/modules/catalogue/catalogue";
import cart from "@/modules/order/cart";
import order from "@/modules/order/order";
import {$openModal} from "@/globals";
import CryptoJS from 'crypto-js';

const key = process.env.ENCRYPT_KEY;


const store = createStore({
  state: {
    asset_version: process.env.ASSET_VERSION,
    address: null,
    options: null,
    loading: false,
    address_updated: false,
  },
  mutations: {
    setAddress(state, address){
      state.address = address
    },
    setLoading(state, val){
      state.loading = val;

      if(val === true) {
        $openModal('loading');
      }
    },
    setAddressUpdated(state){
      state.address_updated = !state.address_updated
    },
    initialiseStore(state) {
      if (localStorage.getItem('r2e_session')) {
        const encryptedState = localStorage.getItem('r2e_session');
        const bytes = CryptoJS.AES.decrypt(encryptedState, key);
        const decryptedState = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        this.replaceState(Object.assign(state, decryptedState));
      }
    },
    resetState(state){
      state.asset_version = process.env.ASSET_VERSION;
      state.address = null;
      state.options = null;
      state.loading = false;
      state.address_updated = false;
      state.discount_code = null;
    }
  },
  getters: {
    getAddress: (state) => state.address,
    getLoading: (state) => state.loading,
    addressUpdated: (state) => state.address_updated,
    getAssetVersion: (state) => state.asset_version,
    getDiscountCode: (state) => state.discount_code,
  },
  actions: {
    setAddress({commit}, address){
      commit('setAddress', address);
    },
    setAddressUpdated({commit}){
      commit('setAddressUpdated');
    },
    setLoading({commit}, val){
      commit('setLoading', val);
    },
    setLocale({commit}, locale) {
      commit('setLocale',locale)
    },
    resetSate({commit}) {
      commit('resetState');
      commit('order/reset');
      commit('cart/reset');
      commit('user/reset');
    }
  },
  modules: {
    user,
    catalogue,
    cart,
    order,
  }
});

store.subscribe((mutation, state) => {
  const encryptedState = CryptoJS.AES.encrypt(JSON.stringify(state), key).toString();
  localStorage.setItem('r2e_session', encryptedState);
});

export default store;
