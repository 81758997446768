<template>
  <app-modal-mini
    :submit-txt="__t('app.add-comment')"
    @submitted="setComment"
  >
    <template #header>
      <h3>{{ __t('app.modal.comment.title') }}</h3>
    </template>

    <template #main>
      <Textarea
        id="comment"
        :value="item.comment"
        :placeholder="__t('app.modal.comment.title')"
        @set="val => setVal(val)"
      />
    </template>
  </app-modal-mini>
</template>

<script>
  import {__t} from "@/globals";
  import {Textarea} from "@/ui";
  import {setComment} from "@/modules/order/services/CartService";
  import AppModalMini from "@/modals/AppModalMini.vue";
  import {modalController} from "@ionic/vue";

  export default {
    name: "ModalComment",
    components: {AppModalMini, Textarea},
    props: {
      type: {type: String, required: true },
      item: {type: Object, required: false, default: null  },
    },
    data(){
      return {
        comment: null,
      }
    },
    beforeMount() {
      this.comment = this.item.comment;
    },
    methods: {
      __t,
      setVal(val){
        this.comment = val;
      },
      setComment(){
        setComment(this.item.uuid, this.comment);
        modalController.dismiss();
      }
    },

  }
</script>

<style lang="scss">

</style>
