import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import session from "@/modules/session";
import * as Sentry from '@sentry/vue';
import Smartlook from 'smartlook-client'
import { Integrations } from '@sentry/tracing';
import { IonicVue } from '@ionic/vue';
import '@ionic/vue/css/core.css';
import '@ionic/vue/css/normalize.css';
import 'swiper/css';
import '@ionic/vue/css/ionic-swiper.css';
import 'animate.css';
import './registerServiceWorker';
import {loadTranslations} from "@/helpers/translator.js";
import axios from "axios";
import '@/theme/ready2eat/scss/app.scss';
import '@/scss/index.scss';
import { App as CapacitorApp } from '@capacitor/app';
import OneSignal from "onesignal-cordova-plugin";
import FirebaseWebConfig from "@/services/firebase/FirebaseWebConfig.js";
import PlatformService from "@/services/PlatformService.js";
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import {Browser} from "@capacitor/browser";


async function initApp() {

  /**
   * -----------------------------------------
   * APP INIT
   * -----------------------------------------
   */
  const app = createApp(App)
    .use(IonicVue)
    .use(session)
    .use(router)


  /**
   * -----------------------------------------
   * MIXINS
   * -----------------------------------------
   */
  app.mixin({
    beforeCreate() {
      this.$store.commit('initialiseStore');
    },
    created () {
      axios.defaults.baseURL = null;
      axios.defaults.headers.common = {
        "X-API-ID": process.env.API_KEY_ID,
        "X-API-SECRET": process.env.API_KEY_SECRET,
        "X-APP-VERSION": localStorage.getItem('app_version') ?? import.meta.env.VITE_APP_VERSION,
      };
      axios.defaults.headers.common['Accept-Language'] =  localStorage.getItem('locale') ?? 'nl';
      axios.defaults.headers.common['Accept'] = 'application/json';
      axios.defaults.withCredentials = true;

      if(PlatformService.isNative()){
        document.documentElement.setAttribute('data-mode', 'native');
      }
    },
  });

  /**
   * -----------------------------------------
   * SMARTLOOK
   * -----------------------------------------
   */
  if(PlatformService.isWeb() && PlatformService.isProduction()){
    Smartlook.init(process.env.SMARTLOOK_KEY);
  }

  /**
   * -----------------------------------------
   * SENTRY
   * -----------------------------------------
   */
  if(PlatformService.isProduction()){
    Sentry.init({
      app: createApp(App),
      dsn: process.env.SENTRY_DSN_KEY,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [process.env.DEV_URL, process.env.BASE_URL, /^\//],
        }),
      ],
      tracesSampleRate: 0
    });
  }

  /**
   * -----------------------------------------
   * VERSION
   * -----------------------------------------
   */
  function setVersion() {
    const version = import.meta.env.VITE_APP_VERSION;

    if (PlatformService.isNative()) {
      if (!localStorage.getItem('app_version')) {
        localStorage.setItem('app_version', version);
      }
    } else {
      localStorage.setItem('app_version', version);
    }
  }

  setVersion();

  await initFirebase();

  await initOneSignal();

  initBrowserRedirect();
  /**
   * -----------------------------------------
   * Translations
   * -----------------------------------------
   */
  await loadTranslations().then(() => {
    app.mount('#app');
  });


  await loadGoogleMapsApi();
}

window.initMap = () => {
  console.log('Google Maps API loaded');
};

/**
 * -----------------------------------------
 * Google Maps
 * -----------------------------------------
 */
async function loadGoogleMapsApi() {
  return new Promise((resolve, reject) => {
    const scriptId = 'google-maps-api-script';
    if (document.getElementById(scriptId)) {
      resolve();
      return;
    }

    const apiKey = process.env.GOOGLE_MAPS_WEB_API_KEY;

    if (!apiKey) {
      reject(new Error('Google Maps API key is missing'));
      return;
    }

    const script = document.createElement('script');
    script.id = scriptId;
    script.async = true;
    script.defer = true;
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error('Failed to load Google Maps API'));
    document.head.appendChild(script);
  });
}

/**
 * -----------------------------------------
 * Deep links
 * -----------------------------------------
 */
if(PlatformService.isNative()){
  CapacitorApp.addListener('appUrlOpen', (event) => {
    if(event.url.includes('ready2eat://app.ready2eat.be/')){
      const slug = event.url.replace('ready2eat://app.ready2eat.be/', '');
      router.push({ path: slug }).catch((err) => {
        if (err.name !== 'NavigationDuplicated') {
          console.error(err);
        }
      });
    }
  });
}

/**
 * -----------------------------------------
 * OneSignal
 * -----------------------------------------
 */
async function initOneSignal() {
  if(PlatformService.isNative()){
    OneSignal.initialize(process.env.ONESIGNAL_APP_ID);
    await OneSignal.Notifications.requestPermission();
  }
}

/**
 * -----------------------------------------
 * Initialize Firebase
 * -----------------------------------------
 */
async function initFirebase() {
  if (!PlatformService.isNative()) {
    const firebaseConfig = new FirebaseWebConfig();
    window.firebase_app = firebaseConfig.app();
  } else if (PlatformService.isNative() && PlatformService.isIOS()) {
    await FirebaseCrashlytics.log({
      message: 'App started'
    });
  }
}

/**
 * -----------------------------------------
 * Initialize Browser redirect
 * -----------------------------------------
 */
function initBrowserRedirect() {
  if (PlatformService.isNative() && PlatformService.isIOS()) {
    CapacitorApp.addListener('appUrlOpen', (event) => {
      if (event.url && event.url.startsWith('ready2eat://')) {
        Browser.close();
      }
    });
  }
}



initApp();
