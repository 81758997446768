import router from "@/router";

export default class routerHelper {

  /**
   *
   * @returns {ReturnType<Router["go"]>|Promise<NavigationFailure | void | undefined>}
   */
  static goBack()
  {
    const currentRoute = router.currentRoute.value;

    if(currentRoute.name === "not-found"){
      return router.push({name : 'catalogue'})
    }

    return router.back();

  }

  /**
   *
   * @param route
   * @returns {boolean}
   */
  static currentRoute(route)
  {
    return router.currentRoute.value.name === route;
  }


  /**
   *
   * @returns String
   */
  static getCurrentRoute()
  {
    return router.currentRoute.value.name;
  }

  /**
   *
   * @param route
   * @param params
   * @returns {Promise<NavigationFailure | void | undefined>}
   */
  static goTo(route, params){
    try {
      return router.push({name: route, params});
    }catch (e) {
      // todo exception
    }

  }
}
