export default {
  namespaced: true,
  state: {
    arrival_timing_preference: 'now',
    preferred_time_of_arrival: null,
    estimated_time_of_arrival: null,
    online_payment: true,
    ready2help: null,
    current_order: null,
    comment: null,
  },
  mutations: {
    setArrivalTimePreference(state, value) {
      state.arrival_timing_preference = value
    },
    setPreferredTimeOfArrival(state, time) {
      state.preferred_time_of_arrival = time
    },
    setEstimatedTimeOfArrival(state, time) {
      state.estimated_time_of_arrival = time
    },
    setOnlinePayment(state, val) {
      state.online_payment = val;
    },
    setReady2Help(state, val) {
      state.ready2help = val;
    },
    setCurrentOrder(state, order) {
      state.current_order = order;
    },
    setComment(state, value) {
      state.comment = value
    },
    reset(state) {
      state.arrival_timing_preference =  'now';
      state.preferred_time_of_arrival =  null;
      state.estimated_time_of_arrival =  null;
      state.online_payment =  true;
      state.ready2help =  null;
      state.comment = null;
      state.discount_code =  null;
    }
  },
  getters: {
    arrival_timing_preference : (state) => state.arrival_timing_preference,
    preferred_time_of_arrival : (state) => state.preferred_time_of_arrival,
    estimated_time_of_arrival : (state) => state.estimated_time_of_arrival,
    online_payment : (state) => state.online_payment,
    ready2help : (state) => state.ready2help,
    current_order : (state) => state.current_order,
    comment : (state) => state.comment,
  },
  actions: {
    setArrivalTimePreference({commit}, value) {
      commit('setArrivalTimePreference', value)
    },
    setPreferredTimeOfArrival({commit}, time) {
      commit('setPreferredTimeOfArrival', time)
    },
    setEstimatedTimeOfArrival({commit}, time) {
      commit('setEstimatedTimeOfArrival', time)
    },
    setOnlinePayment({commit}, val) {
      commit('setOnlinePayment', val)
    },
    setReady2Help({commit}, val) {
      commit('setReady2Help', val)
    },
    setCurrentOrder({commit}, order) {
      commit('setCurrentOrder', order)
    },
    setComment({commit}, comment) {
      commit('setComment', comment)
    },
    reset({commit}) {
      commit('reset')
    },
  },
};
