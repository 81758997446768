<template>
  <ion-app>
    <svg-preloader />
    <ion-router-outlet />
  </ion-app>
</template>

<script setup lang="js">
  import {computed, onBeforeMount, onMounted, ref, watch} from 'vue';
  import {alertController, IonApp, IonRouterOutlet} from "@ionic/vue";
  import session from "@/modules/session";
  import {$openModal, __t} from "@/globals.js";
  import {logout} from "@/modules/auth/services/UserService.js";
  import {apiUrl} from "@/configuration/api.js";
  import axios from "axios";
  import {useRoute, useRouter} from "vue-router";
  import SvgPreloader from "@/modules/global/SvgPreloader.vue";
  import {Network} from "@capacitor/network";
  import PlatformService from "@/services/PlatformService.js";

  const token = computed(() => session.getters['user/token']);
  const tokenExpiry = computed(() => session.getters['user/tokenExpiry']);
  const establishment = computed(() => session.getters['cart/establishment']);
  const address = computed(() => session.getters['getAddress']);
  const delivery = computed(() => session.getters['cart/delivery']);
  const price_delivery = computed(() => session.getters['cart/price_delivery']);
  const testMode = process.env.TEST_MODE;
  const route = useRoute();
  const router = useRouter();
  const connected = ref(true);

  function checkTimeAndApplyTheme() {
    const now = new Date();
    const hour = now.getHours();

    if (hour >= 22 || hour < 6) {
      document.documentElement.setAttribute('data-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-theme', 'light');
    }
  }

  function checkTokenIsExpired() {
    if (token.value && tokenExpiry.value) {
      if(isExpired(tokenExpiry.value)) {
        logout();
        $openModal('token');
      }
    }
  }

  async function checkAppVersion() {
    await axios.get(apiUrl('customer/version')).then(({data}) => {
      const newVersion = data.data.version;

      if(localStorage.getItem('app_version') === newVersion){-
        console.log('app is up to date');
      } else {
        $openModal('update-version', {version: newVersion });
      }
    });
  }

  function isExpired(expiresIn, startDate = new Date()) {
    const expiryDate = new Date(startDate.getTime() + expiresIn * 1000);
    const currentDate = new Date();

    return currentDate > expiryDate;
  }

  function handleRouteChange(to) {
    if (!connected.value) {
      router.push({ name: 'no-connection' });
      return;
    }

    if (to.meta.hideTabs) {
      hideTabsFunction();
    }else {
      document.body.classList.remove('hide-tabs');
    }


    if (to.meta.bgColor) {
      setBgColorFunction();
    }else {
      document.documentElement.removeAttribute('data-color');
    }

    if(to.meta.deliveryCheck){
      console.log('delivery check');

      if(delivery.value && establishment.value && address.value){
        fetchEstablishment(address.value);
      }
    }
  }

  const fetchEstablishment = async (address) => {
    await axios.get(apiUrl(
      `customer/establishment/${establishment.value.slug}?latitude=${address.latitude}&longitude=${address.longitude}`)
    ).then(({data}) => {
      session.commit('cart/setMinimumOrderAmount', data.data.minimum_order_amount);
      session.commit('cart/setDeliverPrice', data.data.delivery_price);

      console.log('api delivery cost:', data.data.delivery_price);

      console.log('cart delivery cost:', price_delivery.value);
    })
  };

  function hideTabsFunction() {
    document.body.classList.add('hide-tabs');
  }

  function setBgColorFunction() {
    document.documentElement.setAttribute('data-color', 'primary');
  }

  async function showTestAlert() {
    const alert = await alertController.create({
      cssClass: 'test-alert',
      header: __t('app.alert.test.title'),
      message: __t('app.alert.test.message'),
      buttons: [__t('app.alert.test.button')],
    });

    alert.onDidDismiss().then(() => {
      localStorage.setItem('test_mode_dismissed', true);
    });

    if(!localStorage.getItem('test_mode_dismissed')){
      await alert.present();
    }
  }

  async function checkInternetConnection() {
    const status = await Network.getStatus();
    connected.value = status.connected;

    if (!connected.value) {
      await router.push({ name: 'no-connection' });
    }

    Network.addListener('networkStatusChange', (status) => {
      connected.value = status.connected;

      if (!connected.value) {
        router.push({ name: 'no-connection' });
      }
    });
  }

  onBeforeMount(() => {
    checkTimeAndApplyTheme();
    checkTokenIsExpired();
  });

  watch(route, (newRoute) => {
    handleRouteChange(newRoute);
  });

  watch(connected, (newConnected) => {
    console.log('connected:', newConnected);
  });

  onMounted(() => {
    handleRouteChange(route);

    if(PlatformService.isNative()){
      checkInternetConnection();
    }

    if(testMode){
      setTimeout(() => {
        showTestAlert();
      }, 2000);
    }

    setTimeout(() => {
      if(!PlatformService.isNative()){
        checkAppVersion();
      }
    }, 2000);
  });
</script>

<style lang="scss">
  .alert-wrapper.sc-ion-alert-md {
    background-color: var(--r2e-white);
    font-family: 'Poppins', sans-serif;
  }

  .ios .test-alert button.alert-button {
    font-weight: 600;
    font-size: 1.2rem;
  }
</style>
