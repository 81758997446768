import session from "@/modules/session";

function addToCart(item){
  session.commit('cart/addToCart', item);

  //setDeliveryCostByEstablishment(item.article.establishment_id);
}

function updateCart(){
  session.commit('cart/updateCart');
}
function addQuantity(item){
  const cartItem = item;
  cartItem.quantity++;
  cartItem.price_total = (cartItem.price_unit + cartItem.price_supplements) * (cartItem.quantity);

  addToCart(cartItem);
  updateCart();
}

function minQuantity(item){
  if(item.quantity > 1){
    const cartItem = item;
    cartItem.quantity--;
    cartItem.price_total = (cartItem.price_unit + cartItem.price_supplements) * (cartItem.quantity);

    addToCart(cartItem);
    updateCart();
  }

  return item;
}

function addQuantityAndGetItem(item){
  const cartItem = item;
  cartItem.quantity++;
  cartItem.price_total = (cartItem.price_unit + cartItem.price_supplements) * (cartItem.quantity);

  return cartItem;
}

function minQuantityAndGetItem(item){
  if(item.quantity > 1){
    const cartItem = item;
    cartItem.quantity--;
    cartItem.price_total = (cartItem.price_unit + cartItem.price_supplements) * (cartItem.quantity);

    return cartItem;
  }
}

function updateSupplementsAndGetItem(item){
  let total = 0;

  item.supplement_groups.forEach(group => {
    group.supplements.forEach((supplement) => {
      if(supplement.selected === true){

        const supplement_price = supplement[session.getters["cart/delivery"]
          ? 'price_delivery'
          : 'price_takeout']

        total += supplement_price;
      }
    })
  });

  const cartItem = item;

  cartItem.price_supplements = total;

  cartItem.price_total = (item.price_unit + total) * (item.quantity);

  return cartItem;
}

function removeItem(item){
  session.commit('cart/removeItem', item);
  updateCart();
}

function setComment(uuid, comment){
  session.commit('cart/setComment', {uuid: uuid, comment: comment});
}

function getComment(uuid) {
  return session.getters['cart/items'].find(item => item.uuid === uuid).comment
}

function getSupplementsString(uuid){
  const cartItem = session.getters['cart/items'].find(item => item.uuid === uuid);
  const selected = [];

  if(!cartItem){
    return null;
  }

  cartItem.supplement_groups.forEach(group => {
    group.supplements.forEach(supplement => {
      if(supplement.selected === true || supplement.selected === 1){
        selected.push(supplement.value)
      }
    })
  })

  return selected.join(', ');
}
function toggleDelivery(){
  session.commit('cart/toggleDelivery');
}

// function setDeliveryCostByEstablishmentId(id){
//   // todo: get axios response when changing location
//   // set delivery cost
// }

// function setDeliveryCostByAddress(address){
//   // todo: get axios response when changing location
//   // set delivery cost
// }


export {
  addToCart,
  addQuantity,
  minQuantity,
  removeItem,
  addQuantityAndGetItem,
  minQuantityAndGetItem,
  updateCart,
  updateSupplementsAndGetItem,
  getSupplementsString,
  toggleDelivery,
  setComment,
  getComment
}
