<template>
  <div class="cart-item-counter space-between">
    <button
      class="reset count-button"
      @click="emit('min')"
    >
      <Icon name="min" />
    </button>

    <span class="value">{{ value }}</span>
    <button
      class="reset count-button"
      @click="emit('add')"
    >
      <Icon name="add" />
    </button>
  </div>
</template>

<script setup>
  import { defineProps, defineEmits } from 'vue';
  import Icon from "@/ui/icon/Icon.vue";

  defineProps({
    value: { type: Number, required: true }
  });

  const emit = defineEmits(['min', 'add']);
</script>

<style lang="scss">
.cart-item-counter {
  display: flex;
  align-items: center;
  width: 7rem;

  span {
    color: var(--r2e-secondary);
    font-weight: 500;
    font-size: 1.6rem;
  }

  button.count-button {
    background-color: var(--r2e-secondary-100);
    height: 2rem;
    width: 2rem;
    color: var(--r2e-secondary);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;

    svg {
      width: 0.6rem;
      height: 0.6rem;
      fill: var(--r2e-secondary);
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &:active, &:hover {
      transform: scale(1.02);
      background-color: var(--r2e-secondary);

      svg {
        fill: #fff;
      }
    }
  }
}

[data-theme='dark'] {
  .cart-item-counter {
    span {
      color: var(--r2e-white);
    }

    button.count-button {
      background-color: var(--r2e-secondary-600);

      svg {
        fill: var(--r2e-white);
      }

      &:active, &:hover {
        transform: scale(1.02);
        background-color: var(--r2e-primary);

        svg {
          fill: #fff;
        }
      }
    }
  }
}
</style>
