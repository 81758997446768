<template>
  <div class="order-follow-link">
    <a :href="`${__t('nav.order-follow')}/${order.nr}`" @click.prevent="navigateToOrder">
      <div class="order-icon">
        <Icon name="bag" />
      </div>
      <div class="body">
        <h3>{{ __t('nav.follow-order') }}</h3>
        <span class="nr">#{{ order.nr }}</span>
      </div>
      <Icon id="arrow-right" name="chevron" />
    </a>
  </div>
</template>

<script setup>
  import { defineProps, defineEmits } from 'vue';
  import Icon from "@/ui/icon/Icon.vue";
  import { __t } from "@/globals";

  const props = defineProps({
    order: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['navigate']);

  function navigateToOrder() {
    emit('navigate', 'order-follow-by-nr', { nr: props.order.nr });
  }
</script>

<style lang="scss">
.order-follow-link {
  background-color: var(--r2e-primary-100);
  height: 5.5rem;
  border-radius: 10px;
  margin-bottom: var(--margin-m);
  display: flex;
  align-items: center;
  position: relative;
  transition: var(--effect);

  @media(min-height: 700px) {
    margin-bottom: var(--margin-l)
  }

  a {
    display: flex;
    align-items: center;
    height: 4rem;
    margin-bottom: 0;
    width: 100%;
  }

  &:hover {
    transform: scale(1.02);

    a {
      div.body {
        h3 {
          color: var(--r2e-primary);
        }
      }
    }

  }

  div.order-icon {
    width: 4rem;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background-color: var(--r2e-primary);
    margin-left: calc((5.5rem - 4rem) / 2);

    svg {
      width: 2.1rem;
      height: 2.1rem;
      fill: #fff;
    }
  }

  div.body {
    height: 4rem;
    margin-left: var(--margin-s);
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: center;

    h3 {
      transition: var(--effect);
      font-size: 1rem;
      margin-bottom: 0;
      line-height: 1;
    }

    span.nr {
      line-height: 1;
      margin-top: 0.5rem;
      font-size: 1rem;
      color: var(--r2e-secondary-200);
      font-weight: 500;
    }

    @media(min-width: 400px) {
      h3 {
        font-size: 1.2rem;
      }

    }
  }

  #arrow-right {
    position: absolute;
    right: var(--margin-s);
    top: calc(50% - 0.6rem);

    svg {
      fill: var(--r2e-primary);
      transform: rotate(180deg);
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}

[data-theme="dark"] .order-follow-link {
  background-color: var(--r2e-primary-700);

  a {
    color: var(--r2e-white)
  }

  div.body {
    h3 {
      color: var(--r2e-white);
    }

    span.nr {
      color: var(--r2e-white);
    }
  }
}
</style>
