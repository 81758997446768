<template>
  <ion-content class="ion-padding content-radius" overflow-scroll="false">
    <div class="inner-content">
      <div class="modal-header" />
      <div class="modal-main mb-l">
        <div class="mb-m">
          <p>{{ __t('contact.del.modal.body') }}</p>
        </div>
        <Input
          id="email"
          type="email"
          :value="inputs.email.value"
          :label=" __t('app.email')"
          :placeholder="__t('app.email')"
          :error="inputs.email.error ? inputs.email.error_message : null"
          @set="val => inputs.email.value = val"
          @remove-error="inputs.email.error = false"
        />
      </div>
    </div>
  </ion-content>
  <ion-footer style="box-shadow: none">
    <div class="modal-footer">
      <app-button
        :disabled="buttonDisabled"
        @click="submitEmail"
      >
        {{ __t('contact.del.modal.button') }}
      </app-button>
    </div>
  </ion-footer>
</template>

<script setup>
  import {ref} from 'vue';
  import { $showToast, __t } from '@/globals';
  import { modalController, IonContent } from "@ionic/vue";
  import { Input } from "@/ui";
  import axios from "axios";
  import { apiUrl } from "@/configuration/api.js";
  import AppButton from "@/ui/button/AppButton.vue";

  const buttonDisabled = ref(false);

  const inputs = ref({
    email: {
      value: null,
      error: false,
      error_message: __t('error.input.email'),
      type: 'email'
    }
  });

  const submitEmail = async () => {
    buttonDisabled.value = true;

    if (!inputs.value.email.value) {
      inputs.value.email.error = true;
      buttonDisabled.value = false;
    } else {
      inputs.value.email.error = false;
    }

    if (!inputs.value.email.error) {
      try {
        await axios.post(apiUrl('request-account-delete'), {
          email: inputs.value.email.value,
        });

        $showToast(__t('success.send-request-delete'), 'success', 3000);
        await modalController.dismiss();
      } catch (error) {
        $showToast(__t('error.send-request-delete'), 'error', 5000);
      } finally {
        buttonDisabled.value = false;
      }
    } else {
      buttonDisabled.value = false;
    }
  };
</script>


<style lang="scss">
.ion-modal-delete-account {
  --height: 45rem;

  .modal-main {
    margin: auto auto;
    max-width: 38rem;

    p {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--r2e-secondary-200);
      line-height: 1.7;
    }
  }

  .modal-footer {
    max-width: 40rem;
    margin: auto auto var(--margin-l);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .error-message {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 var(--margin-s);
    height: 3rem;
    background-color: var(--r2e-primary-100);
    margin: var(--margin-m) auto;
    font-size: 1rem;
    font-weight: 500;
    color: var(--r2e-error);

    max-width: 36rem;

    @media(min-width: 1080px) {
      max-width: 46rem;
    }
  }
}

[data-theme='dark'] {
  .ion-modal-search-initial {
    h3 {
      color: var(--r2e-white);

      svg {
        fill: var(--r2e-white);
      }
    }

    .modal-footer {
      button {
        background-color: var(--r2e-primary);
        color: var(--r2e-white);
      }
    }
  }
}

</style>
