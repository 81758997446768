<template>
  <ion-header style="padding-top: var(--ion-safe-area-top)" />
  <ion-content class="ion-padding content-radius" overflow-scroll="false">
    <div class="inner-content">
      <div class="modal-header">
        <h3>
          <Icon name="contact-user" />
          {{ __t('checkout.contact.title') }}
        </h3>
      </div>
      <div class="modal-main mb-l">
        <div class="modal-contact-fields">
          <form @submit.prevent>
            <div class="input-group group-2">
              <Input
                id="first_name"
                type="text"
                name="first_name"
                :value="inputs.first_name.value"
                :label="__t('app.first_name')"
                :placeholder="__t('app.first_name')"
                :error="inputs.first_name.error ? inputs.first_name.error_message : null"
                @set="val => inputs.first_name.value = val"
                @remove-error="inputs.first_name.error = false"
                @focus-out="val => setValue(inputs.first_name, val)"
              />
              <Input
                id="last_name"
                type="text"
                name="last_name"
                :value="inputs.last_name.value"
                :label="__t('app.last_name')"
                :placeholder="__t('app.last_name')"
                :error="inputs.last_name.error ? inputs.last_name.error_message : null"
                @set="val => inputs.last_name.value = val"
                @remove-error="inputs.last_name.error = false"
                @focus-out="val => setValue(inputs.last_name, val)"
              />
            </div>
            <Input
              id="email"
              type="email"
              name="email"
              :disabled="user?.id && user.email"
              :value="inputs.email.value"
              :label="__t('app.email')"
              :placeholder="__t('app.email')"
              :error="inputs.email.error ? inputs.email.error_message : null"
              @set="val => inputs.email.value = val"
              @remove-error="inputs.email.error = false"
              @focus-out="val => setValue(inputs.email, val)"
            />
            <Input
              id="phone"
              type="tel"
              name="phone"
              :value="inputs.phone.value"
              :label="__t('app.phone')"
              :placeholder="__t('app.phone')"
              :error="inputs.phone.error ? inputs.phone.error_message : null"
              @set="val => inputs.phone.value = val"
              @remove-error="inputs.phone.error = false"
              @focus-out="val => setValue(inputs.phone, val)"
            />

            <template v-if="!inputs.id.value">
              <div class="toggle-group">
                <RegisterToggle />
              </div>


              <template v-if="register">
                <Input
                  id="password"
                  type="password"
                  name="password"
                  :value="inputs.password.value"
                  :label="__t('app.password')"
                  :placeholder="__t('app.password')"
                  :error="inputs.password.error ? inputs.password.error_message : null"
                  @set="val => inputs.password.value = val"
                  @remove-error="inputs.password.error = false"
                  @focus-out="val => setValue(inputs.password, val)"
                />
                <Input
                  id="password_repeat"
                  type="password"
                  name="password_repeat"
                  :value="inputs.password_repeat.value"
                  :label="__t('app.password-repeat')"
                  :placeholder="__t('app.password-repeat')"
                  :error="inputs.password_repeat.error ? inputs.password_repeat.error_message : null"
                  @set="val => inputs.password_repeat.value = val"
                  @remove-error="inputs.password_repeat.error = false"
                  @focus-out="val => setValue(inputs.password_repeat, val)"
                />
              </template>
            </template>
          </form>
        </div>
      </div>
      <div class="modal-footer">
        <AppButton
          :class="{'disabled' : buttonDisabled}"
          @click="submitForm"
        >
          {{ __t('checkout.user-info.submit') }}
        </AppButton>

        <span @click="close">{{ __t('app.modal.cancel') }}</span>
      </div>
    </div>
  </ion-content>
</template>

<script>
  import {Button, Icon, Input} from "@/ui";
  import RegisterToggle from "@/modules/order/parts/RegisterToggle.vue";
  import {$showToast, __t} from "@/globals";
  import session from "@/modules/session";
  import {getAddressString} from "@/modules/order/services/AddressService";
  import {setUser} from "@/modules/auth/services/UserService";
  import {checkEmail} from "@/modules/global/helpers/domHelper";
  import {IonContent, IonHeader, IonModal, modalController} from "@ionic/vue";
  import AppModalMini from "@/modals/AppModalMini.vue";
  import AppButton from "@/ui/button/AppButton.vue";

  export default {
    name: "ModalContactFields",
    components: {AppButton, IonHeader, IonContent, Button, Icon, RegisterToggle, Input},
    emits: ['close', 'submitted'],
    data() {
      return {
        buttonDisabled: false,
        loading: true,
        showSubmit: true,
        submitFixed: true,
        updatedAddress: false,
        address: null,
        inputs: {
          id: {
            value: null
          },
          first_name: {
            value: null,
            error: false,
            error_message: __t('error.input.first_name')
          },
          last_name: {
            value: null,
            error: false,
            error_message: __t('error.input.last_name')
          },
          email: {
            value: null,
            error: false,
            error_message: __t('error.input.email')
          },
          phone: {
            value: null,
            error: false,
            error_message: __t('error.input.phone')
          },
          password: {
            value: null,
            error: false,
            error_message: __t('error.input.password')
          },
          password_repeat: {
            value: null,
            error: false,
            error_message: __t('error.input.password-repeat')
          },
        }

      }
    },
    computed: {
      establishment() {
        return session.getters["cart/establishment"];
      },
      delivery() {
        return session.getters['cart/delivery'];
      },
      register() {
        return session.getters['user/register'];
      },
      user() {
        return session.getters['user/user'];
      }
    },
    beforeMount() {
      this.inputs.id.value = this.user?.id;
      this.inputs.first_name.value = this.user?.first_name;
      this.inputs.last_name.value = this.user?.last_name;
      this.inputs.phone.value = this.user?.phone;
      this.inputs.email.value = this.user?.email;
      this.inputs.password.value = this.user?.password;
      this.inputs.password_repeat.value = this.user?.password;
    },
    methods: {
      getAddressString,
      __t,
      setShowSubmit(val) {
        this.showSubmit = val;
      },
      setSubmitFixed(fixed) {
        this.submitFixed = fixed;
      },
      setValue(input, val) {
        if (this.checkout) {
          input.value = val;
          this.validate();
        }
      },
      validate() {
        let response = true;

        response = this.validateContactDetails();

        if (!response) {
          return false
        }

        this.setUserByPayload();
        return response;
      },
      submitForm() {
        this.doSubmit()
      },
      close() {
        modalController.dismiss();
      },
      doSubmit() {
        if (this.validate()) {
          const payload = {
            id: this.inputs.id.value,
            first_name: this.inputs.first_name.value,
            last_name: this.inputs.last_name.value,
            email: this.inputs.email.value,
            phone: this.inputs.phone.value,
            password: this.inputs.password.value,
            register: this.register
          };

          setUser(payload);

          $showToast(__t('success.order-delivery-details'), 'success');

          this.close();
          return;
        }
        $showToast(__t('error.order-delivery-details'), 'error')
      },
      validateContactDetails() {
        let response = true;

        Object.entries(this.inputs).forEach(([key, inputObj]) => {
          inputObj.error = false;

          if (key === 'id') {
            return;
          }

          if (key === 'password' || key === 'password_repeat') {
            if (this.register) {

              if (inputObj.value === null || inputObj.value === '') {
                inputObj.error = true;
                response = false;
              }

              if (this.inputs.password.value?.length < 8) {
                this.inputs.password.error = true;
                response = false;
              }

              if (this.inputs.password.value !== this.inputs.password_repeat.value) {
                this.inputs.password_repeat.error = true;
                response = false;
              }
            }

            return;
          }

          if (inputObj.value === null || inputObj.value === '') {
            inputObj.error = true;
            response = false;
          }

          if (key === 'email') {
            if (!checkEmail(inputObj.value)) {
              inputObj.error = true;
              response = false;
            }
          }

        });

        return response;
      },
      setUserByPayload() {
        if (this.checkout) {
          const payload = {
            id: this.inputs.id.value,
            first_name: this.inputs.first_name.value,
            last_name: this.inputs.last_name.value,
            email: this.inputs.email.value,
            phone: this.inputs.phone.value,
            password: this.inputs.password.value,
            register: this.register
          };

          return setUser(payload);
        }
      }
    }
  }
</script>

<style lang="scss">
.ion-modal-mini.ion-modal-mini-contact {
  --height:100%;
  --border-radius: 1rem;
}
.modal-contact-fields {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: auto;

  .r2e-input {
    margin-bottom: var(--margin-m);
  }

  @media(max-height: 800px) and (min-width: 720px) {
    border-radius: 1rem;
  }

  .modal-header {
    margin-bottom: var(--margin-l);

    h3 {
      display: flex;
      align-items: center;
      font-size: 1.4rem;

      svg {
        width: 2rem;
        height: 2rem;
        margin-right: var(--margin-s);
      }
    }
  }

  .toggle-group {
    margin: var(--margin-m);
    margin-top: var(--margin-l);
    display: flex;
    justify-content: center;
  }

  .modal-main, .modal-footer {
    max-width: 40rem;
    margin: auto auto var(--margin-l);
  }


  .modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .button-wrapper {
      width: 100%;
      max-width: 36rem;
    }

    span {
      font-size: 1.2rem;
      text-align: center;
      margin-top: var(--margin-m);
      font-weight: 500;
      color: var(--color-grey-500);
      cursor: pointer;

      &:hover {
        color: var(--color-black);
      }
    }
  }
}
</style>
