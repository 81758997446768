<template>
  <AppModal>
    <div class="navigation">
      <navigation-header
        @close="close"
        @navigate="goTo"
      />
      <div class="nav-main">
        <container>
          <navigation-order-follow-link
            v-if="currentOrder"
            :order="currentOrder"
            @navigate="goTo"
          />
          <navigation-links
            :current-order="currentOrder"
            @navigate="goTo"
          />
          <div class="d-flex">
            <LangSwitcher class="m-auto" />
          </div>
          <div v-if="authenticated" class="d-flex">
            <navigation-logout-button
              @logout="doLogout"
            />
          </div>
        </container>
      </div>
    </div>
  </AppModal>
</template>

<script setup>
  import {computed} from 'vue';
  import {useRouter} from 'vue-router';
  import {modalController} from '@ionic/vue';
  import {logout} from '@/modules/auth/services/UserService';
  import session from '@/modules/session';
  import LangSwitcher from '@/modules/global/parts/LangSwitcher.vue';
  import AppModal from '@/modals/AppModal.vue';
  import Container from '@/layout/layout/Container.vue';
  import NavigationLogoutButton from "@/modules/navigation/parts/NavigationLogoutButton.vue";
  import NavigationLinks from "@/modules/navigation/parts/NavigationLinks.vue";
  import NavigationOrderFollowLink from "@/modules/navigation/parts/NavigationOrderFollowLink.vue";
  import NavigationHeader from "@/modules/navigation/parts/NavigationHeader.vue";
  import {$showToast, __t} from "@/globals.js";

  const router = useRouter();

  const currentOrder = computed(() => session.getters['order/current_order']);
  const authenticated = computed(() => session.getters['user/isAuthenticated']);


  function close() {
    modalController.dismiss();
  }

  function doLogout() {
    logout();
    close();

    if (process.env.DEVICE_APP_MODE === 'native') {
      router.push({name: 'native.login'});
    } else {
      router.push({name: 'login'});
      close();
    }

    $showToast(__t('success.logout'), 'success');
  }

  function goTo(route, params) {
    close();
    router.push({name: route, params});
  }
</script>

<style lang="scss">
@import "@/scss/smooth";

.navigation {
  display: flex;
  border-radius: 0;
  width: 100%;
  background-color: var(--r2e-background);
  padding-top: var(--padding-m);
  flex-direction: column;

  a:hover {
    cursor: pointer;
  }

  .nav-main {
    padding: var(--padding-m) var(--padding-xs);
    padding-top: 0;

    @media(min-height: 700px) {
      padding: var(--padding-l) var(--padding-xs);
      padding-top: 0;
    }

    .d-flex {
      display: flex;
    }
  }
}

[data-mode='native'] {
  .navigation {
    padding-top: 6rem;
  }
}

[data-theme='dark'] {
  .navigation {
    background-color: var(--r2e-secondary-600);
  }
}
</style>
