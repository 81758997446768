<template>
  <ion-header />
  <ion-content class="ion-padding content-radius" overflow-scroll="false">
    <div class="inner-content">
      <div class="modal-header">
        <slot name="header" />
      </div>
      <div class="modal-main">
        <slot name="main" />
      </div>
      <div class="modal-footer">
        <slot name="footer">
          <div class="button-group">
            <app-button :disabled="buttonDisabled" @click="submitted">
              {{ submitTxt ?? __t('app.modal.submit') }}
            </app-button>
          </div>


          <span v-if="!hideCancel" @click="close">{{ __t('app.modal.cancel') }}</span>
        </slot>
      </div>
    </div>
  </ion-content>
</template>

<script setup>
  import {defineProps, defineEmits} from "vue";
  import {IonHeader, IonContent, modalController} from "@ionic/vue";
  import {__t} from "@/globals";
  import AppButton from "@/ui/button/AppButton.vue";

  defineProps({
    submitTxt: {
      type: String,
      required: false,
      default: null
    },
    buttonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    hideCancel: {
      type: Boolean,
      required: false,
      default: false
    }
  })

  const emit = defineEmits(['submitted']);

  const close = () => {
    modalController.dismiss();
  }
  const submitted = () => {
    emit('submitted');
  }
</script>

<style lang="scss">
.ion-modal-mini {
  --height: 34rem;
  --border-radius: 1rem;

  .modal-main {
    max-width: 40rem;
    margin: auto auto var(--margin-l);
  }

  .modal-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .button-group {
      display: flex;
      justify-content: center;
    }

    span {
      font-size: 1.2rem;
      text-align: center;
      margin-top: var(--margin-m);
      font-weight: 500;
      color: var(--color-grey-500);
      cursor: pointer;

      &:hover {
        color: var(--color-black);
      }
    }
  }
}

.inner-content {
  padding: var(--margin-l) var(--padding-s);
}

.modal-header {
  margin-bottom: var(--margin-l);

  h3 {
    display: flex;
    align-items: center;
    font-size: 1.4rem;

    svg {
      width: 2rem;
      height: 2rem;
      margin-right: var(--margin-s);
    }
  }
}

[data-theme='dark'] {
  ion-modal {
    --background: var(--r2e-secondary);
  }

  div.modal-header {
    h3 {
      color: var(--r2e-white);
      svg {
        fill: var(--r2e-white);
      }
    }
  }

  div.modal-main {
    .toggle-group {
      color: var(--r2e-white);

      span {
        color: var(--r2e-white);
      }

    }

  }

  div.modal-footer {

    button.r2e-button {
      background-color: var(--r2e-primary);
      color: var(--r2e-white);
    }

    span {
      color: var(--r2e-secondary-200);
    }
  }
}



</style>
