<template>
  <span class="tooltip" :class="position">
    <slot />
  </span>
</template>

<script>
  export default {
    name: "Tooltip",
    props: {
      position: { type: String, required: false, default: 'right' }
    }
  }
</script>

<style lang="scss">
.tooltip {
  top: calc(100% + var(--margin-s));
  position: absolute;
  left: calc(-50% + var(--margin-m));
  padding: 0 var(--padding-xs);
  background-color: var(--r2e-secondary);
  width: max-content;
  height: 2rem;
  font-weight: 500;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  z-index: 99;
  color: #fff;
  font-size: 1.1rem;
  margin-left: -10px;

  &::before {
    content: "";
    position: absolute;
    left: 15px;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid var(--color-black);
    transform: translateX(-50%);
  }

  &.left {
    top: calc(100% + var(--margin-xs));
    left: auto;
    right: calc(-50% + var(--margin-m));
    margin-left: auto;
    margin-right: -15px;

    &::before {
      left: auto;
      right: 15px;
    }
  }
}

[data-theme='dark'] {
  .tooltip {
    background-color: var(--r2e-secondary-600);
  }
}
</style>
