import session from "@/modules/session";

export default {
  namespaced: true,
  state: {
    items: [],
    establishment: null,
    establishmentUpdated: false,
    price_sub_total: 0,
    price_delivery: 0,
    price_service: 0.50,
    price_fee: 0,
    min_order_amount: 0,
    discount_amount: 0,
    discount_code: null,
    discount_min_order_amount: 0,
    price_total: 0,
    delivery: true,
    deliveryUpdated: false,
    cart_updated: false
  },
  mutations: {
    addToCart(state, item) {
      const existingItemIndex = state.items.findIndex(cartItem => cartItem.uuid === item.uuid);

      if (state.establishment?.id !== item.article.establishment_id) {
        state.establishment = item.article.establishment;
        state.establishmentUpdated = !state.establishmentUpdated;
        state.items = [];
      }

      if (existingItemIndex !== -1) {
        state.items[existingItemIndex] = item;
      } else {
        state.items.push(item);
      }

      state.cart_updated = !state.cart_updated;
    },
    removeItem(state, item) {
      state.items = state.items.filter(cartItem => cartItem.uuid !== item.uuid);
      state.cart_updated = !state.cart_updated;
    },
    setComment(state, payload) {
      state.items.find(cartItem => cartItem.uuid === payload.uuid).comment = payload.comment;
      state.cart_updated = !state.cart_updated;
    },
    setEstablishment(state, establishment) {
      state.establishment = establishment;
      state.price_delivery = establishment.delivery_price;
      state.establishmentUpdated = !state.establishmentUpdated;

      if (establishment.delivery === 'takeout') {
        session.commit('cart/setDelivery', false);
      }

      state.cart_updated = !state.cart_updated;
    },
    setDeliverPrice(state, price) {
      state.price_delivery = price;
    },
    setMinimumOrderAmount(state, amount) {
      state.min_order_amount = amount;
    },
    setDiscountCode(state, code) {
      state.discount_code = code;
    },
    setDiscountAmount(state, amount) {
      state.discount_amount = amount;
    },
    setDiscountMinOrderAmount(state, amount) {
      state.discount_min_order_amount = amount;
    },
    updateCart(state) {
      state.price_sub_total = state.items.reduce((total, item) => total + (item.price_total || 0), 0);
      state.cart_updated = !state.cart_updated;
    },
    reset(state) {
      state.items = [];
      state.establishment = null;
      state.price_sub_total = 0;
      state.price_delivery = 0;
      state.price_service = 0.50;
      state.price_fee = 0;
      state.price_total = 0;
      state.discount_code = null;
      state.discount_min_order_amount = 0;
      state.min_order_amount = 0;
      state.discount_amount = 0;
      state.cart_updated = !state.cart_updated;
    },
    toggleDelivery(state) {
      state.delivery = !state.delivery;

      if (state.delivery === false) {
        session.commit('order/setOnlinePayment', true);
      }

      state.deliveryUpdated = !state.deliveryUpdated;

      state.cart_updated = !state.cart_updated;
    },
    setDelivery(state, payload) {
      state.delivery = payload;
    },
  },
  getters: {
    items: (state) => state.items,
    item_total_count: (state) => state.items.reduce((accum, current) => {
      return accum + current.quantity;
    }, 0),
    delivery: (state) => state.delivery,
    deliveryUpdated: (state) => state.deliveryUpdated,
    establishment: (state) => state.establishment,
    price_sub_total: (state) => state.price_sub_total,
    price_service: (state) => state.price_service,
    price_fee: (state) => state.price_fee,
    cart_updated: (state) => state.cart_updated,
    establishmentUpdated: (state) => state.establishmentUpdated,
    discount_code: (state) => state.discount_code,
    discount_amount: (state) => state.discount_amount,
    discount_min_order_amount: (state) => state.discount_min_order_amount,
    price_delivery: (state) => state.delivery ? state.price_delivery : null,
    price_total: (state) => state.delivery
      ? (state.price_sub_total - (state.price_sub_total >= state.discount_min_order_amount ? state.discount_amount : 0))
      + state.price_service
      + state.price_fee
      + state.price_delivery
      : (state.price_sub_total - (state.price_sub_total >= state.discount_min_order_amount ? state.discount_amount : 0))
      + state.price_service
      + state.price_fee

  },
  actions: {
    addToCart({commit}, item) {
      commit('addToCart', item)
    },
    setDeliverPrice({commit}, price) {
      commit('setDeliverPrice', price);
    },
    setMinimumOrderAmount({commit}, amount) {
      commit('setMinimumOrderAmount', amount);
    },
    removeItem({commit}, item) {
      commit('removeItem', item)
    },
    setEstablishment({commit}, establishment) {
      commit('setEstablishment', establishment)
    },
    updateCart({commit}, item) {
      commit('updateCart', item)
    },
    toggleDelivery({commit}) {
      commit('toggleDelivery')
    },
    setDelivery({commit}, payload) {
      commit('setDelivery', payload);
    },
    setComment({commit}, payload) {
      commit('setComment', payload);
    },
    applyDiscountCode({commit}, code){
      commit('setDiscountCode', code);
      commit('setDiscountAmount', code.discount_amount);
      commit('setDiscountMinOrderAmount', code.min_order_amount);
    },
    removeDiscountCode({commit}) {
      commit('setDiscountCode', null);
      commit('setDiscountAmount', 0);
      commit('setDiscountMinOrderAmount', 0);
    },
    reset({commit}) {
      commit('reset')
    },
  },
};
