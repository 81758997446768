export default class Address {

  constructor() {
    this.id = null;
    this.street = null;
    this.number = null;
    this.default = null;
    this.bus = null;
    this.latitude = null;
    this.longitude = null;
    this.city = {
      name : null,
      zip : null,
      province : null,
      country : null,
      country_code : null,
    };
    this.save = false;
  }
}
