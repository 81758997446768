<template>
  <div ref="panel" class="app-panel">
    <div class="inner-content">
      <h3>{{ title }}</h3>
      <div class="body">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
  import {defineProps, ref} from "vue";

  defineProps({
    title: {
      type: String,
      required: true,
    },
  });

  const panel = ref(null);
</script>

<style lang="scss">
.app-panel {
  width: 100%;
  position: relative;
  box-shadow: var(--shadow);
  border-radius: 1rem;
  background-color: transparent;

  .smooth-corners {
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
  }

  .inner-content {
    padding: var(--padding-s);
    background-color: var(--r2e-white);
    border-radius: 1rem;
    position: relative;
    z-index: 1;
    width: 100%;
    min-height: 9.5rem;

    h3 {
      font-size: 1.4rem;
      margin-bottom: var(--margin-s);
    }

    div.body {
      font-size: 1.2rem;
      font-weight: 500;
      color: var(--r2e-secondary-200);

      p {
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--r2e-secondary-200);
      }

      ul {
        li {
          margin-bottom: var(--margin-s);
          list-style: none;
          font-size: 1.2rem;
          font-weight: 500;
          color: var(--r2e-secondary-200);
          display: flex;
          justify-content: space-between;

          span {
            font-size: 1.2rem;
            font-weight: 500;
            color: var(--r2e-secondary-200);

            a {
              font-weight: 500;
              color: var(--r2e-secondary);

              &:hover {
                color: var(--r2e-primary);
              }
            }
          }
        }
      }
    }
  }
}

[data-theme='dark'] {
  .app-panel {
    .inner-content {
      background-color: var(--r2e-secondary);

      h3 {
        color: var(--r2e-white);
      }

      div.body, div.body p {
        color: var(--r2e-secondary-200);
      }

      div.body {
        ul {
          li {
            span {
              a {
                color: var(--r2e-primary);
              }
            }
          }
        }
      }
    }
  }
}
</style>
