<template>
  <div
    class="supplement-item space-between"
    :class="{'active': item.active, 'max' : max && !item.selected}"
    @click.prevent="updateSupplement(item)"
  >
    <h4>
      {{ item.value }}
      <span v-if="showPrice" class="ml-s price">
        (+ {{ delivery ? valuta(item.price_delivery) : valuta(item.price_takeout) }})
      </span>

      <SupplementAlcohol v-if="item.alcohol" />
      <SupplementAllergies :allergies="item.allergies" />
    </h4>

    <Checkbox
      v-if="props.type === 'multiple'"
      :class="{'max': max && !item.selected}"
      :checked="(Boolean(item.selected))"
    />
    <Radio v-else :active="(Boolean(item.selected))" :name="item.value" />
  </div>
</template>

<script setup>
  import {defineProps, computed, defineEmits} from 'vue';
  import {Checkbox, Radio} from "@/ui";
  import {valuta} from "@/modules/global/helpers/domHelper";
  import SupplementAllergies from "@/modals/parts/SupplementAllergies.vue";
  import SupplementAlcohol from "@/modals/parts/SupplementAlcohol.vue";

  const props = defineProps({
    item: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    delivery: {
      type: Boolean,
      required: true
    },
    max: {
      type: Boolean,
      required: false,
    }
  });

  const updateSupplement = (item) => {
    if(props.max && !item.selected) return;
    emit('updateSupplement', item);
  };

  const emit = defineEmits(['updateSupplement']);

  const showPrice = computed(() => {
    return props.item.price_delivery !== null && (props.item.price_delivery > 0 || props.item.price_takeout > 0);
  });
</script>

<style lang="scss">
/* Add relevant CSS here */

.max {
  pointer-events: none;
  opacity: 0.4;

  h4 {
    pointer-events: none;
  }
}

.checkbox.max {
  pointer-events: none;
  opacity: 0.2;
}
</style>
