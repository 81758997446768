<template>
  <div class="radio-button">
    <input
      :id="name"
      type="radio"
      :value="active"
      :name="name"
    >
    <div class="radio" :class="{ active : active }">
      <Icon v-if="!active" name="radio" />
      <Icon v-else name="radio-active" />
    </div>
    <label v-if="label" :class="{ active : active }" v-html="label" />
  </div>
</template>
<script>
  import Icon from "@/ui/icon/Icon.vue";

  export default {
    name: "R2eRadioButton",
    components: {Icon},
    props: {
      active: {type: Boolean, required: true},
      label: {type: String, required: false, default: null},
      name: {type: String, required: true}
    }
  }
</script>

<style lang="scss">
.radio-button {
  display: flex;
  align-items: center;

  input {
    display: none;
  }

  div.radio {
    svg {
      width: 2.4rem;
      height: 2.4rem;
      color: var(--r2e-secondary-200);
    }

    &.active {
      svg {
        color: var(--r2e-primary);
      }

    }
  }

  label {
    font-weight: 500;
    color: var(--r2e-secondary);

    &.active {
      color: var(--r2e-primary);
    }
  }
}
</style>
