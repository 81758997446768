<template>
  <div class="preloader">
    <img
      v-for="icon in icons"
      :key="icon"
      :src="icon.path"
      alt="icon"
    >
  </div>
</template>

<script setup>
  import {ref, onMounted} from 'vue';

  const resolveSvgPath = (iconPath) => {
    // Use dynamic import to resolve paths correctly with Vite
    return new URL(iconPath, import.meta.url).href;
  };

  // List your SVG icons here
  const icons = ref([
    { key: 'chevron', path: resolveSvgPath('@/svg/ui/chevron.svg') },
    { key: 'phone', path: resolveSvgPath('@/svg/ui/phone.svg') },
    { key: 'check', path: resolveSvgPath('@/svg/ui/check.svg') },
    { key: 'plus', path: resolveSvgPath('@/svg/ui/plus.svg') },
    { key: 'logo', path: resolveSvgPath('@/svg/logo/logo.svg') },
    { key: 'logo-big-icon', path: resolveSvgPath('@/svg/logo/logo-big-icon.svg') },
    { key: 'logo-text', path: resolveSvgPath('@/svg/logo/logo-text.svg') },
    { key: 'exit', path: resolveSvgPath('@/svg/ui/exit.svg') },
    { key: 'edit', path: resolveSvgPath('@/svg/ui/edit.svg') },
    { key: 'user', path: resolveSvgPath('@/svg/ui/user.svg') },
    { key: 'bag', path: resolveSvgPath('@/svg/ui/bag.svg') },
    { key: 'history', path: resolveSvgPath('@/svg/ui/history.svg') },
    { key: 'addresses', path: resolveSvgPath('@/svg/ui/addresses.svg') },
    { key: 'favorites', path: resolveSvgPath('@/svg/ui/favorites.svg') },
    { key: 'info', path: resolveSvgPath('@/svg/ui/info.svg') },
    { key: 'info-full', path: resolveSvgPath('@/svg/ui/info-full.svg') },
    { key: 'search', path: resolveSvgPath('@/svg/ui/search.svg') },
    { key: 'location', path: resolveSvgPath('@/svg/ui/location.svg') },
    { key: 'clock', path: resolveSvgPath('@/svg/ui/clock.svg') },
    { key: 'star', path: resolveSvgPath('@/svg/ui/star.svg') },
    { key: 'heart', path: resolveSvgPath('@/svg/ui/heart.svg') },
    { key: 'filter', path: resolveSvgPath('@/svg/ui/filter.svg') },
    { key: 'minus', path: resolveSvgPath('@/svg/ui/minus.svg') },
    { key: 'success', path: resolveSvgPath('@/svg/status/success.svg') },
    { key: 'error', path: resolveSvgPath('@/svg/status/error.svg') },
    { key: 'arrow', path: resolveSvgPath('@/svg/ui/arrow.svg') },
    { key: 'delete', path: resolveSvgPath('@/svg/ui/delete.svg') },
    { key: 'user-location', path: resolveSvgPath('@/svg/ui/user-location.svg') },
    { key: 'discount', path: resolveSvgPath('@/svg/ui/discount.svg') },
    { key: 'card', path: resolveSvgPath('@/svg/ui/card.svg') },
    { key: 'r2h', path: resolveSvgPath('@/svg/ui/r2h.svg') },
    { key: 'cash', path: resolveSvgPath('@/svg/ui/cash.svg') },
    { key: 'kbc', path: resolveSvgPath('@/svg/providers/kbc.svg') },
    { key: 'belfius', path: resolveSvgPath('@/svg/providers/belfius.svg') },
    { key: 'bancontact', path: resolveSvgPath('@/svg/providers/bancontact.svg') },
    { key: 'mastercard', path: resolveSvgPath('@/svg/providers/mastercard.svg') },
    { key: 'visa', path: resolveSvgPath('@/svg/providers/visa.svg') },
    { key: 'address-establishment', path: resolveSvgPath('@/svg/ui/address-establishment.svg') },
    { key: 'empty', path: resolveSvgPath('@/svg/img/empty.svg') },
    { key: 'status.queuing', path: resolveSvgPath('@/svg/status/queuing.svg') },
    { key: 'status.received', path: resolveSvgPath('@/svg/status/received.svg') },
    { key: 'status.confirmed', path: resolveSvgPath('@/svg/status/confirmed.svg') },
    { key: 'status.paid', path: resolveSvgPath('@/svg/status/paid.svg') },
    { key: 'status.in_preparation', path: resolveSvgPath('@/svg/status/in_preparation.svg') },
    { key: 'status.ready_to_go', path: resolveSvgPath('@/svg/status/ready_to_go.svg') },
    { key: 'status.in_delivery', path: resolveSvgPath('@/svg/status/in_delivery.svg') },
    { key: 'status.complete', path: resolveSvgPath('@/svg/status/complete.svg') },
    { key: 'loader', path: resolveSvgPath('@/svg/loader/loader.svg') },
    { key: 'invoice', path: resolveSvgPath('@/svg/ui/invoice.svg') },
    { key: 'order', path: resolveSvgPath('@/svg/ui/order.svg') },
    { key: 'allergy.lactose', path: resolveSvgPath('@/svg/allergies/lactose.svg') },
    { key: 'allergy.gluten', path: resolveSvgPath('@/svg/allergies/gluten.svg') },
    { key: 'allergy.nuts', path: resolveSvgPath('@/svg/allergies/nuts.svg') },
    { key: 'allergy.eggs', path: resolveSvgPath('@/svg/allergies/eggs.svg') },
    { key: 'allergy.fish', path: resolveSvgPath('@/svg/allergies/fish.svg') },
    { key: 'allergy.crustaceans', path: resolveSvgPath('@/svg/allergies/crustaceans.svg') },
    { key: 'allergy.soy', path: resolveSvgPath('@/svg/allergies/soy.svg') },
    { key: 'allergy.pinda', path: resolveSvgPath('@/svg/allergies/pinda.svg') },
    { key: 'allergy.sesame', path: resolveSvgPath('@/svg/allergies/sesame.svg') },
    { key: 'allergy.sulfur_dioxide_sulphites', path: resolveSvgPath('@/svg/allergies/sulfur_dioxide_&_sulphites.svg') },
    { key: 'allergy.lupine', path: resolveSvgPath('@/svg/allergies/lupine.svg') },
    { key: 'allergy.mollusks', path: resolveSvgPath('@/svg/allergies/mollusks.svg') },
    { key: 'allergy.mustard', path: resolveSvgPath('@/svg/allergies/mustard.svg') },
    { key: 'allergy.celery', path: resolveSvgPath('@/svg/allergies/celery.svg') },
    { key: 'alcohol', path: resolveSvgPath('@/svg/ui/alcohol.svg') },
    { key: 'sort', path: resolveSvgPath('@/svg/ui/sort.svg') },
    { key: 'radio', path: resolveSvgPath('@/svg/ui/radio.svg') },
    { key: 'radio-active', path: resolveSvgPath('@/svg/ui/radio-active.svg') },
    { key: 'update', path: resolveSvgPath('@/svg/ui/update.svg') },
    { key: 'not-found', path: resolveSvgPath('@/svg/errors/not-found.svg') },
    { key: 'server', path: resolveSvgPath('@/svg/errors/server.svg') },
    { key: 'internet', path: resolveSvgPath('@/svg/errors/internet.svg') },
    { key: 'contact-user', path: resolveSvgPath('@/svg/ui/contact-user.svg') },
    { key: 'alert', path: resolveSvgPath('@/svg/errors/alert.svg') },
    { key: 'catalogue', path: resolveSvgPath('@/svg/ui/catalogue.svg') },
    { key: 'cart-button-background', path: resolveSvgPath('@/svg/ui/cart-button-background.svg') },
    { key: 'no-results', path: resolveSvgPath('@/svg/errors/no-results.svg') },
    { key: 'settings', path: resolveSvgPath('@/svg/ui/settings.svg') },
    { key: 'facebook', path: resolveSvgPath('@/svg/providers/facebook.svg') },
    { key: 'google', path: resolveSvgPath('@/svg/providers/google.svg') },
    { key: 'add', path: resolveSvgPath('@/svg/ui/plus.svg') },
    { key: 'min', path: resolveSvgPath('@/svg/ui/minus.svg') },
    { key: 'map-light', path: resolveSvgPath('@/svg/map/map-light.svg') },
    { key: 'map-dark', path: resolveSvgPath('@/svg/map/map-dark.svg') },
    { key: 'eye', path: resolveSvgPath('@/svg/ui/eye.svg') },
    { key: 'eye-closed', path: resolveSvgPath('@/svg/ui/eye-closed.svg') },
    { key: 'mollie', path: resolveSvgPath('@/svg/providers/mollie.svg') },
    { key: 'version', path: resolveSvgPath('@/svg/ui/version.svg') },
    { key: 'beverage', path: resolveSvgPath('@/svg/ui/beverage.svg') },
  ]);


  onMounted(() => {
    if(process.env.APP_ENV !== 'local'){
      icons.value.forEach(icon => {
        const img = new Image();
        img.src = icon.path;
      });
    }

  });
</script>


<style lang="scss">
.preloader {
  display: none;
}
</style>
