<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet />

      <ion-tab-bar class="bottom-tabs">
        <ion-tab-button class="bottom-tab" tab="tab1" href="/tabs/order">
          <span class="selection-effect" />
          <Icon name="catalogue" />
        </ion-tab-button>

        <ion-tab-button class="bottom-tab" tab="tab2" href="/tabs/cart">
          <span class="selection-effect" />
          <div class="bottom-cart-button">
            <span class="article-count">{{ cart_items }} {{ cart_items === 1 ? __t('app.cart.item') : __t('app.cart.items') }}</span>
            <span class="price">
              {{ price_total === 0.50 ? valuta(0) : valuta(price_total) }}

              <Icon name="bag" />
            </span>
          </div>
        </ion-tab-button>

        <ion-tab-button class="bottom-tab" tab="tab3" href="/tabs/account">
          <span class="selection-effect" />
          <Icon name="user" />
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup>
  import {IonTabBar, IonTabButton, IonTabs, IonRouterOutlet, IonPage} from '@ionic/vue';
  import Icon from "@/ui/icon/Icon.vue";
  import {valuta} from "@/modules/global/helpers/domHelper";
  import {computed} from "vue";
  import session from "@/modules/session";
  import {__t} from "@/globals";

  const price_total = computed(() => session.getters['cart/price_total']);
  const cart_items = computed(() => session.getters['cart/item_total_count']);
</script>

<style lang="scss">
.bottom-tabs {
  height: 6rem;
  background: var(--r2e-primary-gradient);
  padding-bottom: var(--ion-safe-area-bottom);

  .bottom-tab {
    height: 100%;

    color: var(--r2e-white);
    background-color: transparent;
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .selection-effect {
      transition: var(--effect);
      border-radius: 0 0 99rem 99rem;
      width: 0;
      margin: auto;
      top: 0;
      position: absolute;
      height: 0.7rem;
      background-color: rgba(0, 0, 0, 0.33);
    }

    &.tab-selected {
      //border-top: 3px solid rgba(0, 0, 0, 0.33);

      .selection-effect {
        width: 100%;
      }
    }

    &:hover {
      svg {
        color: var(--r2e-white);
      }
    }

    span {
      color: var(--r2e-white);
      font-weight: 500;
      font-size: 1rem;
    }

    svg {
      fill: var(--r2e-white);
      width: 1.8rem;
      height: 2.3rem;
    }
  }
}

.bottom-cart-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 15.8rem;
  width: max-content;
  border-radius: 99rem;
  background-color: var(--r2e-secondary-600);
  height: 3.4rem;
  flex-wrap: wrap;

  span.article-count {
    width: 50%;
    height: 100%;
    font-size: 1rem;
    align-items: center;
    display: flex;
    padding-left: var(--padding-s);
  }

  span.price {
    margin-left: auto;
    display: flex;
    justify-content: right;
    align-items: center;
    width: 50%;
    font-size: 1.3rem;
    padding-right: var(--padding-s);

    svg {
      width: 1.1rem;
      margin-left: var(--margin-xs);
      margin-right: 0;
    }
  }
}
</style>

