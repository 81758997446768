<template>
  <Teleport to="body">
    <div ref="modal-wrap" class="modal-wrap">
      <div ref="modal" class="modal-mini">
        <div class="modal-header">
          <slot name="header" />
        </div>
        <div class="modal-main">
          <slot name="main" />
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <Button :class="{'disabled' : buttonDisabled}" @click="$emit('submitted');$emit('close')">
              {{ submitTxt ?? __t('app.modal.submit') }}
            </Button>

            <span @click="$emit('close')">{{ __t('app.modal.cancel') }}</span>
          </slot>
        </div>
      </div>
    </div>
  </Teleport>
</template>

<script>
  import {Button} from "@/ui";
  import {__t} from "@/globals";
  import { gsap } from "gsap";
  import {onClickOutside} from "@vueuse/core";

  export default {
    name: "ModalMini",
    components: {Button},
    props: {
      submitTxt: {type: String, required: false, default: null},
      buttonDisabled: {type: Boolean, required: false, default: false},
    },
    emits: ['close', 'submitted'],
    mounted() {
      gsap.to(this.$refs["modal-wrap"], {duration: 0.30, opacity: 1, ease: "Power2.easeOut"});
      if(window.innerWidth < 700){
        gsap.to(this.$refs.modal, {duration: 0.24, bottom: "0%", ease: "Power2.easeOut"});
      }else {
        gsap.to(this.$refs.modal, {duration: 0.24, scale: 1, ease: "Power2.easeOut"});
      }

      onClickOutside(this.$refs["modal"], () => this.close())

    },
    methods: {
      __t,
      close(){
        gsap.to(this.$refs["modal-wrap"], {duration: 0.30, opacity: 0, ease: "Power2.easeOut"});
        if(window.innerWidth < 700){
          gsap.to(this.$refs.modal, {duration: 0.24, bottom: "-100%", ease: "Power2.easeOut"});
        }else {
          gsap.to(this.$refs.modal, {duration: 0.24, scale: 0.9, ease: "Power2.easeOut"});
        }
        setTimeout(() => {
          this.$emit('close');
        }, 500)

      }
    }
  }
</script>

<style lang="scss">


</style>
