<template>
  <app-modal-mini
    :button-disabled="disabled"
    @submitted="submit"
  >
    <template #header>
      <h3>
        <Icon name="r2h" />
        {{ __t('checkout.ready2help.title') }}
      </h3>
    </template>

    <template #main>
      <p class="mb-m">
        {{ __t(('app.ready2help.body')) }}
      </p>

      <Select
        v-if="!loading"
        :placeholder="__t('app.select-organisation')"
        :selected="sponsor"
        :options="options"
        label="name"
        @setter="val => setSponsor(val)"
      />
    </template>
  </app-modal-mini>
</template>

<script>
  import AppModalMini from "@/modals/AppModalMini.vue";
  import {$showToast, __t} from "@/globals";
  import {Icon, Select} from "@/ui";
  import session from "@/modules/session";
  import axios from "axios";
  import {apiUrl} from "@/configuration/api";
  import {modalController} from "@ionic/vue";

  export default {
    name: "ModalReady2Help",
    components: {Icon, AppModalMini, Select},
    data(){
      return {
        loading: true,
        preferred_time_of_arrival : null,
        disabled: true,
        sponsor: null,
        options : []
      }
    },
    beforeMount() {
      this.fetchSponsors();

      this.sponsor = session.getters['order/ready2help'];
    },
    methods: {
      __t,
      setSponsor(sponsor){
        this.sponsor = sponsor;
        this.disabled = false;
      },
      submit(){
        session.commit('order/setReady2Help', this.sponsor);
        $showToast(__t('success.checkout.ready2help'), 'success');
        modalController.dismiss();
      },
      async fetchSponsors(){
        await axios.get(apiUrl('organizations')).then(({data}) => {

          data.data.forEach(organization => {
            this.options.push({
              id: organization.id,
              name: organization.name,
            })
          });

          this.loading = false;
        })
      }
    },
  }
</script>

<style lang="scss">
.ion-modal-mini.ion-modal-ready2help {
  --height: 50rem;

  p {
    font-size: 1.4rem;
    color: var(--r2e-secondary-200);
    line-height: 1.7;
  }
}
</style>
