<template>
  <i class="icon">
    <component :is="iconComponent" />
  </i>
</template>

<script setup>
  import { defineAsyncComponent, computed, defineProps } from 'vue';

  const props = defineProps({
    name: {
      type: String,
      required: true
    },
  });

  const icons = {
    'chevron': defineAsyncComponent(() => import('@/svg/ui/chevron.svg')),
    'phone': defineAsyncComponent(() => import('@/svg/ui/phone.svg')),
    'check': defineAsyncComponent(() => import('@/svg/ui/check.svg')),
    'plus': defineAsyncComponent(() => import('@/svg/ui/plus.svg')),
    'logo': defineAsyncComponent(() => import('@/svg/logo/logo.svg')),
    'logo-big-icon': defineAsyncComponent(() => import('@/svg/logo/logo-big-icon.svg')),
    'logo-text': defineAsyncComponent(() => import('@/svg/logo/logo-text.svg')),
    'exit': defineAsyncComponent(() => import('@/svg/ui/exit.svg')),
    'edit': defineAsyncComponent(() => import('@/svg/ui/edit.svg')),
    'user': defineAsyncComponent(() => import('@/svg/ui/user.svg')),
    'bag': defineAsyncComponent(() => import('@/svg/ui/bag.svg')),
    'history': defineAsyncComponent(() => import('@/svg/ui/history.svg')),
    'addresses': defineAsyncComponent(() => import('@/svg/ui/addresses.svg')),
    'favorites': defineAsyncComponent(() => import('@/svg/ui/favorites.svg')),
    'info': defineAsyncComponent(() => import('@/svg/ui/info.svg')),
    'info-full': defineAsyncComponent(() => import('@/svg/ui/info-full.svg')),
    'search': defineAsyncComponent(() => import('@/svg/ui/search.svg')),
    'location': defineAsyncComponent(() => import('@/svg/ui/location.svg')),
    'clock': defineAsyncComponent(() => import('@/svg/ui/clock.svg')),
    'star': defineAsyncComponent(() => import('@/svg/ui/star.svg')),
    'heart': defineAsyncComponent(() => import('@/svg/ui/heart.svg')),
    'filter': defineAsyncComponent(() => import('@/svg/ui/filter.svg')),
    'minus': defineAsyncComponent(() => import('@/svg/ui/minus.svg')),
    'success': defineAsyncComponent(() => import('@/svg/status/success.svg')),
    'error': defineAsyncComponent(() => import('@/svg/status/error.svg')),
    'arrow': defineAsyncComponent(() => import('@/svg/ui/arrow.svg')),
    'delete': defineAsyncComponent(() => import('@/svg/ui/delete.svg')),
    'user-location': defineAsyncComponent(() => import('@/svg/ui/user-location.svg')),
    'discount': defineAsyncComponent(() => import('@/svg/ui/discount.svg')),
    'card': defineAsyncComponent(() => import('@/svg/ui/card.svg')),
    'r2h': defineAsyncComponent(() => import('@/svg/ui/r2h.svg')),
    'cash': defineAsyncComponent(() => import('@/svg/ui/cash.svg')),
    'kbc': defineAsyncComponent(() => import('@/svg/providers/kbc.svg')),
    'belfius': defineAsyncComponent(() => import('@/svg/providers/belfius.svg')),
    'bancontact': defineAsyncComponent(() => import('@/svg/providers/bancontact.svg')),
    'mastercard': defineAsyncComponent(() => import('@/svg/providers/mastercard.svg')),
    'visa': defineAsyncComponent(() => import('@/svg/providers/visa.svg')),
    'address-establishment': defineAsyncComponent(() => import('@/svg/ui/address-establishment.svg')),
    'empty': defineAsyncComponent(() => import('@/svg/img/empty.svg')),
    'status.queuing': defineAsyncComponent(() => import('@/svg/status/queuing.svg')),
    'status.received': defineAsyncComponent(() => import('@/svg/status/received.svg')),
    'status.confirmed': defineAsyncComponent(() => import('@/svg/status/confirmed.svg')),
    'status.paid': defineAsyncComponent(() => import('@/svg/status/paid.svg')),
    'status.in_preparation': defineAsyncComponent(() => import('@/svg/status/in_preparation.svg')),
    'status.ready_to_go': defineAsyncComponent(() => import('@/svg/status/ready_to_go.svg')),
    'status.in_delivery': defineAsyncComponent(() => import('@/svg/status/in_delivery.svg')),
    'status.complete': defineAsyncComponent(() => import('@/svg/status/complete.svg')),
    'loader': defineAsyncComponent(() => import('@/svg/loader/loader.svg')),
    'invoice': defineAsyncComponent(() => import('@/svg/ui/invoice.svg')),
    'order': defineAsyncComponent(() => import('@/svg/ui/order.svg')),
    'allergy.info': defineAsyncComponent(() => import('@/svg/ui/info.svg')),
    'allergy.lactose': defineAsyncComponent(() => import('@/svg/allergies/lactose.svg')),
    'allergy.gluten': defineAsyncComponent(() => import('@/svg/allergies/gluten.svg')),
    'allergy.nuts': defineAsyncComponent(() => import('@/svg/allergies/nuts.svg')),
    'allergy.eggs': defineAsyncComponent(() => import('@/svg/allergies/eggs.svg')),
    'allergy.fish': defineAsyncComponent(() => import('@/svg/allergies/fish.svg')),
    'allergy.crustaceans': defineAsyncComponent(() => import('@/svg/allergies/crustaceans.svg')),
    'allergy.soy': defineAsyncComponent(() => import('@/svg/allergies/soy.svg')),
    'allergy.pinda': defineAsyncComponent(() => import('@/svg/allergies/pinda.svg')),
    'allergy.sesame': defineAsyncComponent(() => import('@/svg/allergies/sesame.svg')),
    'allergy.sulfur_dioxide_sulphites': defineAsyncComponent(() => import('@/svg/allergies/sulfur_dioxide_&_sulphites.svg')),
    'allergy.lupine': defineAsyncComponent(() => import('@/svg/allergies/lupine.svg')),
    'allergy.mollusks': defineAsyncComponent(() => import('@/svg/allergies/mollusks.svg')),
    'allergy.mustard': defineAsyncComponent(() => import('@/svg/allergies/mustard.svg')),
    'allergy.celery': defineAsyncComponent(() => import('@/svg/allergies/celery.svg')),
    'alcohol': defineAsyncComponent(() => import('@/svg/ui/alcohol.svg')),
    'sort': defineAsyncComponent(() => import('@/svg/ui/sort.svg')),
    'radio': defineAsyncComponent(() => import('@/svg/ui/radio.svg')),
    'radio-active': defineAsyncComponent(() => import('@/svg/ui/radio-active.svg')),
    'update': defineAsyncComponent(() => import('@/svg/ui/update.svg')),
    'error.not-found': defineAsyncComponent(() => import('@/svg/errors/not-found.svg')),
    'server': defineAsyncComponent(() => import('@/svg/errors/server.svg')),
    'error.internet': defineAsyncComponent(() => import('@/svg/errors/internet.svg')),
    'contact-user': defineAsyncComponent(() => import('@/svg/ui/contact-user.svg')),
    'alert': defineAsyncComponent(() => import('@/svg/errors/alert.svg')),
    'catalogue': defineAsyncComponent(() => import('@/svg/ui/catalogue.svg')),
    'cart-button-background': defineAsyncComponent(() => import('@/svg/ui/cart-button-background.svg')),
    'no-results': defineAsyncComponent(() => import('@/svg/errors/no-results.svg')),
    'settings': defineAsyncComponent(() => import('@/svg/ui/settings.svg')),
    'facebook': defineAsyncComponent(() => import('@/svg/providers/facebook.svg')),
    'google': defineAsyncComponent(() => import('@/svg/providers/google.svg')),
    'add': defineAsyncComponent(() => import('@/svg/ui/plus.svg')),
    'min': defineAsyncComponent(() => import('@/svg/ui/minus.svg')),
    'map-light': defineAsyncComponent(() => import('@/svg/map/map-light.svg')),
    'map-dark': defineAsyncComponent(() => import('@/svg/map/map-dark.svg')),
    'eye' : defineAsyncComponent(() => import('@/svg/ui/eye.svg')),
    'eye-closed' : defineAsyncComponent(() => import('@/svg/ui/eye-closed.svg')),
    'mollie' : defineAsyncComponent(() => import('@/svg/providers/mollie.svg')),
    'version': defineAsyncComponent(() => import('@/svg/ui/version.svg')),
    'beverage': defineAsyncComponent(() => import('@/svg/ui/beverage.svg')),
    'menu': defineAsyncComponent(() => import('@/svg/ui/menu.svg')),
  };

  const iconComponent = computed(() => {
    return icons[props.name] || null;
  });
</script>
